import { createQueryStringFromObject } from "../utils/createQueryStringFromObject";

import { ODataOptions, Params } from "./model";

export const makeRequest = (path: string, options?: ODataOptions): string => {

    const params: Params = {};

    for (const key in options) {
        // eslint-disable-next-line no-prototype-builtins
        if (options.hasOwnProperty(key)) {
            addOption(params, options, key as keyof ODataOptions);
        }
    }

    const queryString = createQueryStringFromObject(params, "$");
    const separator = path.indexOf("?") > -1 ? "&" : "?";

    return `${path}${separator}${queryString}`;
};

const addOption = (params: Params, options: ODataOptions, key: keyof ODataOptions) => {
    const value = options[key];
    if (value !== undefined) {

        if (value === true) {
            params[key] = "true";
            return;
        }

        if (value === false) {
            params[key] = "false";
            return;
        }

        params[key] = value;
    }
};
