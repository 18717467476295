import * as React from "react";
import { connect } from "react-redux";
import { replace, State as RouterState } from "redux-little-router";

import * as toast from "./toasts";

type ToastLevel = "error" | "info" | "warning" | "success";

interface ToastMessageProps {
    message: string;
    autoClose: number | false;
    level: ToastLevel;
    redirect: () => void;
}

const ToastMessageUnconnected: React.SFC<ToastMessageProps> = ({ message, autoClose, level, redirect }) => {

    if (message !== undefined) {
        showLevelToast(message, autoClose, level);
        redirect();
    }

    return null;
};

interface StateProps {
    message: string;
    autoClose: number | false;
    level?: ToastLevel;
    query: any;
    path: string;
}

const mapStateToProps = (state: ToastMessageProps & RouterState): StateProps => {
    return {
        message: state.router.query.ToastMessage,
        autoClose: state.router.query.ToastAutoClose && getAutoCloseValue(state.router.query.ToastAutoClose),
        level: (state.router.query.ToastLevel && state.router.query.ToastLevel.toLowerCase()) as ToastLevel,
        query: state.router.query,
        path: state.router.pathname
    };
};

const showLevelToast = (message: string, autoClose: number | false, level: ToastLevel) => ({
    error: toast.error,
    info: toast.info,
    warning: toast.warning,
    success: toast.success
})[level](message, autoClose);

const getAutoCloseValue = (autoClose: string) => {
    const parsedValue = +autoClose;

    if (parsedValue === 0) {
        return false;
    }

    if (isNaN(parsedValue) === false) {
        return parsedValue;
    }

    return 5000;
};

interface DispatchProps {
    redirect: (query: any, path: string) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
    redirect: (query: any, path: string) => {
        const newQuery = { ...query };
        delete newQuery.ToastMessage;
        delete newQuery.ToastAutoClose;
        delete newQuery.ToastLevel;
        dispatch(replace({ pathname: path, query: newQuery }));
    }
});

const mergeProps = ({ message, autoClose, level, query, path }: StateProps, { redirect }: DispatchProps): ToastMessageProps => ({
    message,
    autoClose,
    level,
    redirect: () => redirect(query, path)
});

export const ToastMessage = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ToastMessageUnconnected);
