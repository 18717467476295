export abstract class FilterValue<T> {
    private value: T;

    constructor(value: T) {
        this.value = value;
    }

    public get() {
        return this.toString(this.value);
    }

    protected abstract toString(value: T): string;
}
