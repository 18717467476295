import * as React from "react";
import { connect } from "react-redux";

import { localTextSelector } from "./selectors";
import { RegionState } from "./model";
import { LocalTextKey } from "./values";

interface LocalTextStateProps {
    text: React.ReactNode;
}

interface LocalTextOwnProps {
    keyName: LocalTextKey;
    children?: (value: React.ReactNode) => React.ReactNode;
}

type LocalTextProps = LocalTextOwnProps & LocalTextStateProps;

const LocalTextUnconnected: React.FunctionComponent<LocalTextProps> = ({ children, text }) => {

    if (children) {
        return (
            <>
                {children(text)}
            </>
        );
    }

    return (
        <>
            {text}
        </>
    );
};

const mapStateToProps = (state: RegionState, { keyName }: LocalTextOwnProps): LocalTextStateProps => ({
    text: localTextSelector(state, keyName)
});

export const LocalText = connect(mapStateToProps)(LocalTextUnconnected);
