import * as React from "react";

export const ResourcingTeamUk = (<>
    <p>Resource Team</p>
    <p>Tel: 0207 374 5645</p>
    <p>Email: <a className="momenta-team-link" href="mailto:resourcing@momentagroup.com">resourcing@momentagroup.com</a></p>
    <p>
        Our Resourcing team of skilled recruiters and administrators will help you find a role that is right for you and guide
        you through your candidate journey. Contact them for queries regarding:
    </p>
    <ul>
        <li>Roles you have seen advertised</li>
        <li>Ongoing applications</li>
        <li>Interview & assessment process</li>
        <li>Job offers</li>
    </ul>
</>);

export const ResourcingTeamAus = (<>
    <p>Resource Team</p>
    <p>Tel: 028871 8801</p>
    <p>Email: <a className="momenta-team-link" href="mailto:resourcing@momentaresourcing.com.au">resourcing@momentaresourcing.com.au</a></p>
    <p>
        Our Resourcing team of skilled recruiters and administrators will help you find a role that is right for you and guide you
        through your candidate journey. Contact them for queries regarding
    :</p>
    <ul>
        <li>Roles you have seen advertised</li>
        <li>Ongoing applications</li>
        <li>Interview & assessment process</li>
        <li>Job offers</li>
    </ul>
</>);

export const ResourcingTeamUsa = (<>
    <p>Resource Team</p>
    <p>Tel: +1 917 781 4652 (Option 2)</p>
    <p>Email: <a className="momenta-team-link" href="mailto:usadmin@momentagroup.com">usadmin@momentagroup.com</a></p>
    <p>
        Our Resourcing team of skilled recruiters and administrators will help you find a role that is right for you and guide you
        through your candidate journey. Contact them for queries regarding
    :</p>
    <ul>
        <li>Roles you have seen advertised</li>
        <li>Ongoing applications</li>
        <li>Interview & assessment process</li>
        <li>Job offers</li>
    </ul>
</>);
