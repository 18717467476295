import * as React from "react";

import { LocalTextValue } from "../model";

const ukMessage = (
    <p>
        A copy of our contractor privacy policy can be found by clicking
        <a href="https://www.momentagroup.com/contractor-privacy-policy/" target="_blank" rel="noopener noreferrer"> <u>here</u></a>.
        A copy of the terms and conditions associated with the use of website/resourcing/placement software can be found by clicking
        <a href="https://momentagroup.com/website-terms-and-conditions/" target="_blank" rel="noopener noreferrer"> <u>here</u></a>.
    </p>
);

const otherRegionsMessage = (
    <p>
        A copy of our privacy policy can be found by clicking
        <a href="https://momentagroup.com/privacy-and-cookie-policy/" target="_blank" rel="noopener noreferrer"> <u>here</u></a>.
        A copy of the terms and conditions associated with the use of website/resourcing/placement software can be found by clicking
        <a href="https://momentagroup.com/website-terms-and-conditions/" target="_blank" rel="noopener noreferrer"> <u>here</u></a>.
    </p>
);

export const termsAndConditionsMessage: LocalTextValue<React.ReactNode> = {
    default: ukMessage,
    Australia: otherRegionsMessage,
    India: otherRegionsMessage
};
