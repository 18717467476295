import * as React from "react";
import { Form, Label } from "semantic-ui-react";
import { ValidationFunction, ValidationOptions, validators } from "not-valid";
import { debounce } from "@neworbit/simpleui-utils";
import MaskedInput from "react-text-mask";

import { InputValidator } from "../validators/input-validator";

export interface SharedProps<TValue> {
    value?: TValue;
    label?: string;
    validation?: ValidationFunction<TValue>[];
    validationOptions?: ValidationOptions;
    onChange?: (value: TValue, valid: boolean) => void;
    placeholder?: string;
    showErrors?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
}

export interface DurationInputComponentProps extends SharedProps<string> {
    onChange?: (value: string, valid: boolean) => void;
    allowNegative?: boolean;
}

export interface DurationInputComponentState {
    value: string;
    errors: string[];
    dirty: boolean;
    touched: boolean;
}

export class DurationInputComponent extends React.Component<DurationInputComponentProps, DurationInputComponentState> {

    private validator: InputValidator<string>;

    private handleChange: (event: any, data: string) => void;

    constructor(props: DurationInputComponentProps) {
        super(props);

        const initialValue = this.getValueOrDefault(props.value);

        this.state = {
            value: initialValue,
            errors: [],
            dirty: props.value !== undefined,
            touched: false
        };

        let validation = props.validation;

        if (props.required) {
            validation = props.validation === undefined
                ? [validators.requiredString()]
                : [validators.requiredString(), ...props.validation];
        }

        this.validator = new InputValidator(validation, props.validationOptions);
        this.handleChange = this.getHandleChange().bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    public render() {
        let errors;

        if (this.state.errors) {
            errors = this.state.errors.map((e, i) => <p key={i}>{e}</p>);
        }

        const showErrors = errors.length > 0 && ((this.state.touched && this.state.dirty) || this.props.showErrors);

        return (
            <div className="field-wrapper">
                <Form.Input
                    label={this.props.label}
                    error={showErrors}
                    readOnly={this.props.readOnly}
                >
                    <MaskedInput
                        mask={this.getInputMask}
                        placeholder={this.props.placeholder}
                        onChange={this.maskedInputOnChange}
                        value={this.state.value}
                        onBlur={this.onBlur}
                        disabled={this.props.disabled}
                        required={this.props.required}
                    />
                </Form.Input>

                {showErrors && <Label basic color="red" pointing>
                    {errors}
                </Label>}
            </div>
        );
    }

    public async UNSAFE_componentWillReceiveProps(props: DurationInputComponentProps) {

        if (props.value === null || props.value === undefined || (this.state.value === props.value)) {
            return;
        }

        const value = props.value;

        const errors = await this.validator.validate(value);

        this.setState({
            value,
            errors,
            dirty: this.state.dirty || value !== this.state.value
        });
    }

    public async UNSAFE_componentWillMount() {
        const errors = await this.validator.validate(this.state.value);
        this.setState({ errors });
        this.emitOnChange(this.state.value, this.state.errors);
    }

    private getInputMask = (value: string) => {
        if (this.props.allowNegative && value.startsWith("-")) {
            return [/-?/, /[0]|[1]|[2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/];
        }

        return [/[0]|[1]|[2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/];
    }

    private maskedInputOnChange = (event: any) => {
        this.handleChange(event, event.target.value);
    }

    private onBlur() {
        this.setState({ touched: this.state.dirty });
    }

    private emitOnChange(value: string, errors: string[]): void {
        if (this.props.onChange) {
            const valid = errors.length === 0;
            this.props.onChange(value, valid);
        }
    }

    private getValueOrDefault(value: string): string {

        if (value !== null && value !== undefined) {
            return value;
        }

        return "";
    }

    private getHandleChange(): (event: any, data: string) => Promise<void> {

        const validateFunc = async (value: string) => {
            const errors = await this.validator.validate(value);

            this.emitOnChange(this.state.value, errors);
            this.setState({ errors });
        };

        const validateDebounced = debounce((value: string) => validateFunc(value), 250);

        return (event: any, data: string) => {

            this.setState({
                value: data,
                dirty: true
            },
            () => this.emitOnChange(data, this.state.errors));

            return validateDebounced(data);
        };
    }
}
