import * as React from "react";
import { Grid, Container } from "semantic-ui-react";

import { LocalTextValue } from "../model";

const UkLandingFooter = (
    <footer className="landing-footer">
        <Container>
            <Grid>
                <Grid.Column computer={14} mobile={16}>
                    <a href="https://momentagroup.com/website-terms-and-conditions/" target="_blank" rel="noopener noreferrer">T&Cs</a>
                    | <a
                        href="https://www.momentagroup.com/contractor-privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy for Contractors and Potential Contractors
                    </a>
                    | <a
                        href="https://www.momentagroup.com/modern-slavery-and-human-trafficking-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Modern Slavery and Human Trafficking Policy
                    </a>
                </Grid.Column>
                <Grid.Column computer={2} mobile={16} floated="right" textAlign="center">
                    <strong>0207 374 6600</strong>
                </Grid.Column>
            </Grid>
        </Container>
    </footer>
);

const UsaLandingFooter = (
    <footer className="landing-footer">
        <Container>
            <Grid>
                <Grid.Column computer={14} mobile={16}>
                    <a href="https://momentagroup.com/website-terms-and-conditions/" target="_blank" rel="noopener noreferrer">T&Cs</a>
                </Grid.Column>
                <Grid.Column computer={2} mobile={16} floated="right" textAlign="center">
                    <strong>+1 917 7814652</strong>
                </Grid.Column>
            </Grid>
        </Container>
    </footer>
);

const AustraliaLandingFooter = (
    <footer className="landing-footer">
        <Container>
            <Grid>
                <Grid.Column computer={14} mobile={16}>
                    <a href="https://momentagroup.com/website-terms-and-conditions/" target="_blank" rel="noopener noreferrer">T&Cs</a>
                </Grid.Column>
                <Grid.Column computer={2} mobile={16} floated="right" textAlign="center">
                    <strong>02 8871 8800</strong>
                </Grid.Column>
            </Grid>
        </Container>
    </footer>
);

const IndiaLandingFooter = (
    <footer className="landing-footer">
        <Container>
            <Grid>
                <Grid.Column computer={14} mobile={16}>
                    <a href="https://momentagroup.com/website-terms-and-conditions/" target="_blank" rel="noopener noreferrer">T&Cs</a>
                    | <a
                        href="https://www.momentagroup.com/contractor-privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy for Contractors and Potential Contractors
                    </a>
                    | <a
                        href="https://www.momentagroup.com/modern-slavery-and-human-trafficking-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Modern Slavery and Human Trafficking Policy
                    </a>
                </Grid.Column>
            </Grid>
        </Container>
    </footer>
);

export const associateLandingFooter: LocalTextValue<React.ReactNode> = {
    default: UkLandingFooter,
    Australia: AustraliaLandingFooter,
    India: IndiaLandingFooter,
    Usa: UsaLandingFooter
};
