export enum CandidateState {
    "Unknown" = 0,
    "Pipeline" = 10,
    "Long List" = 20,
    "Short List" = 30,
    "Under Offer" = 40,
    "On Contract" = 50,
    "Contract Ended" = 100,
}

export enum VettingItemState {
    "Unknown" = 0,
    "Waiting (Associate)" = 10,
    "Ready to Start" = 20,
    "In Progress" = 30,
    "Waiting (External)" = 40,
    "On Hold" = 50,
    "Awaiting Completion" = 60,
    "QC Complete" = 70,
    "Complete" = 80,
    "Closed" = 90
}

export enum TimesheetState {
    "Unknown" = 0,
    "Initial" = 10,
    "Submitted" = 20,
    "Approved" = 30
}

export enum TimeMeasurementType {
    Unknown = 0,
    Days = 10,
    Weeks = 20,
    Months = 30,
    Years = 40
}

export enum NoteCategoryEnum {
    Unknown = 0,
    Call = 10,
    Email = 20,
    Sms = 30,
    Alert = 40,
    Itris = 50,
    Meeting = 60,
    Interview = 70,
    Assessment = 80,
    QC = 90,
    FoundOwnJob = 100,
    Referral = 110,
    Reporting = 120,
    HR = 130,
    ReferenceRequest = 140,
    Vetting = 150,
    ScreeningCall = 160,
    LeaveAbsence = 170
}

export enum Country {
    "Unknown" = 0,
    "Australia" = 36,
    "United Kingdom" = 826,
    "India" = 356,
    "Usa" = 840
}

export const NoteCategory = {
    [NoteCategoryEnum.Unknown]: "Unknown",
    [NoteCategoryEnum.Call]: "Call/Conversation",
    [NoteCategoryEnum.Email]: "Email",
    [NoteCategoryEnum.Sms]: "SMS",
    [NoteCategoryEnum.Alert]: "Flag/Alert",
    [NoteCategoryEnum.Itris]: "Itris",
    [NoteCategoryEnum.Meeting]: "Meeting",
    [NoteCategoryEnum.Interview]: "Interview",
    [NoteCategoryEnum.Assessment]: "Assessment",
    [NoteCategoryEnum.QC]: "QC",
    [NoteCategoryEnum.FoundOwnJob]: "Found Own Job",
    [NoteCategoryEnum.Referral]: "Referral",
    [NoteCategoryEnum.Reporting]: "Reporting",
    [NoteCategoryEnum.HR]: "HR",
    [NoteCategoryEnum.ReferenceRequest]: "Reference Request",
    [NoteCategoryEnum.Vetting]: "Vetting",
    [NoteCategoryEnum.ScreeningCall]: "Screening Call",
    [NoteCategoryEnum.LeaveAbsence]: "Leave/Absence"
};

export const WithdrawCandidateReasons = {
    0: "Unknown",
    10: "Failed – Momenta CV screen",
    20: "Failed – Momenta Tele-screen",
    30: "Failed – Client CV submission",
    40: "Failed - Momenta Assessment",
    50: "Failed - Momenta Interview",
    60: "Failed - Client Assessment",
    70: "Failed - Client Interview",
    80: "Failed - Adverse vetting file",
    90: "Incomplete vetting",
    100: "Withdrawn – Personal",
    110: "Withdrawn – Other role",
    120: "Withdrawn – Non-responsive",
    130: "Withdrawn – Contract concerns",
    140: "Withdrawn – Rate/Role/Location not suitable",
    150: "Withdrawn – Requirement change",
    160: "Withdrawn - Duplicate Profile",
    170: "Withdrawn - Internal change",
    180: "Withdrawn - Changed to different Role",
    190: "Withdrawn - Associate not based in this country",
    // Options only available to associates should go here
    500: "Withdrawn - Personal Reasons",
    510: "Withdrawn - Role not for me",
    520: "Withdrawn - Rate too low",
    530: "Withdrawn - Location not suitable",
    540: "Withdrawn - Secured new role",
    550: "Withdrawn - Remaining in current role",
    560: "Withdrawn - Applying for different role with Momenta",
    570: "Withdrawn - Cannot complete background compliance checks"
};
