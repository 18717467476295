import moment from "moment";

import { Timesheet } from "./model";

export const getEndDate = (start: moment.Moment) => start.clone().add(6, "days");

export const getEndDateTimesheet = (timesheet: Timesheet) => {
    return getEndDate(timesheet.start);
};

export const getTimesheetDateRange  = (timesheet: Timesheet) => {
    const start = timesheet.start;
    const end = timesheet.end || getEndDate(start);
    return `${start.format("LL")} - ${end.format("LL")}`;
};
