import { Action, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { debounce } from "@neworbit/simpleui-utils";

import { getContext } from "../logging/getContext";

import * as toast from "./toasts";

const toastUnauthenticated = () => {
    toast.error("Your session has expired.  Please Sign-In to continue");
};

const friendlyErrorMessage = () => {
    toast.error("Sorry, the application encountered an issue");
};

const toastUnauthenticatedDebounced = debounce(toastUnauthenticated, 500);

const toastDebounced = debounce(friendlyErrorMessage, 1000, true);

export const toastMiddleware: Middleware = (api: MiddlewareAPI<any>) => (next: Dispatch<Action>) => async (action: any) => {

    try {
        return await next(action);
    } catch (e) {
        const message = typeof e === "string" ? e : e.message;
        const development = getContext().environment === "development";

        if (message === "Not authenticated") {
            toastUnauthenticatedDebounced();
        } else if (development) {
            toast.error(message);
        } else {
            toastDebounced();
        }

        throw e;
    }
};
