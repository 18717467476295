import { ReactNode } from "react";
import { ResourcingTeamAus, ResourcingTeamUk, ResourcingTeamUsa } from "src/App/Associate/src/momentaTeams/ResourcingTeam";

import { LocalTextValue } from "../../model";

export const resourcingInfo: LocalTextValue<ReactNode> = {
    "default": ResourcingTeamUk,
    "United Kingdom": ResourcingTeamUk,
    "Australia": ResourcingTeamAus,
    "Usa": ResourcingTeamUsa,
};
