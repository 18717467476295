import * as React from "react";
import { ReferenceConfiguration } from "@momenta/common/referencing/model";

import { LocalTextValue } from "../model";

const ukMessage = (referenceConfiguration: ReferenceConfiguration) => {

    const referenceDurationRequired = referenceConfiguration && referenceConfiguration.referencingPeriod;
    const gapPeriod = referenceConfiguration && referenceConfiguration.nonEmploymentPeriod;

    return (
        <>
            <p>
                Momenta are required by our clients to verify your recent activity before we can place you on a project.
                Please complete the required details below, to include all employment and education during
                the last <strong>{referenceDurationRequired} months</strong>, ensuring the entire period is accounted for.

                If you have any periods during which you were neither employed nor in education that were over <strong>{gapPeriod} days</strong>,
                please ensure you provide details of your activity under the "Not in Employment" option.
            </p>
            <p>
                In addition, we will require the details of any employment or work experience (including voluntary work) you have not included in your CV
                regardless of the relevance to the role you are applying for.

                For more guidance on acceptable gap evidence and different types of employment activity please click
                <strong>
                    <a
                        href="https://momentagroup.com/wp-content/uploads/Reference-Guidance.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"> here</a>
                </strong>.
            </p>
            <p>
                You can save, and later return to this page whilst you are compiling the information.
                Please press submit once you are satisfied that you have entered all the required information.
                Once submit has been pressed, you will not be able to edit.
            </p>
        </>
    );
};

const australiaMessage = (referenceConfiguration: ReferenceConfiguration) => {

    const referenceDurationRequired = referenceConfiguration && referenceConfiguration.referencingPeriod;
    const gapPeriod = referenceConfiguration && referenceConfiguration.nonEmploymentPeriod;

    return (
        <>
            <p>
                Momenta is required by our clients to verify your recent activity before we can place you on a project.
                Please complete the required details below, to include all employment and education during
                the last <strong>{referenceDurationRequired} months</strong>, ensuring the entire period is accounted for. If
                you have any periods during which you were neither employed nor in education that were over <strong>{gapPeriod} days</strong>,
                please ensure you provide details of your activity under the "Not in Employment" option.
            </p>
            <p>
                In addition, we will require the details of any employment or work experience (including voluntary work)
                you have not included in your CV regardless of the relevance to the role you are applying for. For more
                guidance on different types of employment activity please click
                <strong>
                    <a
                        href="https://momentagroup.com/wp-content/uploads/Reference-Guidance.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"> here</a>
                </strong>.
            </p>
            <p>
                You can save, and later return to this page whilst you are compiling the information. Please press submit
                once you are satisfied that you have entered all the required information. Once submit has been pressed,
                you will not be able to edit.
            </p>
        </>
    );
};

export const referencingHeaderMessage: LocalTextValue<(referenceConfiguration: ReferenceConfiguration) => JSX.Element> = {
    default: ukMessage,
    Australia: australiaMessage
};
