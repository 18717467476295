import * as actions from "./actiontypes";
import { GlobalConfig } from "./model";
import { GlobalConfigAction } from "./actions";

export function globalConfigs(state: GlobalConfig[] = [], action: GlobalConfigAction) {
    switch (action.type) {
        case actions.LOAD_GLOBALCONFIG_SUCCESS:
            return action.payload;
        case actions.SAVE_GLOBALCONFIG_SUCCESS:
            return state.map(c => globalConfig(c, action));
        case actions.DELETE_GLOBALCONFIG_SUCCESS:
            return [...state.filter(c => c.id !== action.payload)];
        default:
            return state;
    }
}

function globalConfig(state: GlobalConfig, action: GlobalConfigAction): GlobalConfig {
    switch (action.type) {
        case actions.SAVE_GLOBALCONFIG_SUCCESS:
            if (action.payload.id === state.id) {
                return action.payload;
            }
            return state;
        default:
            return state;
    }
}
