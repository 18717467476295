import * as React from "react";
import { Divider, DropdownItemProps, Header, Segment } from "semantic-ui-react";
import { dateSortAsc } from "@momenta/common";

import { TimeEntryType, TimeTypeConfiguration } from "../hierarchicalConfiguration";

import { TimesheetDay } from "./model";
import { TimesheetDayView } from "./TimesheetDayView";
import { getTimesheetEntryValues } from "./getTimesheetEntryValues";

export interface TimesheetDaysViewProps {
    timesheetDays: TimesheetDay[];
    timeTypeConfigurations: TimeTypeConfiguration[];
    projectTimeType: TimeEntryType;
    timesheetEntryOptions: DropdownItemProps[];
}

const TimesheetDaysView: React.FC<TimesheetDaysViewProps> = ({ timesheetDays, timeTypeConfigurations, projectTimeType, timesheetEntryOptions }) => {

    const daysTotal = timesheetDays
        .map(d => getTimesheetEntryValues(d, projectTimeType, TimeEntryType.Days, timeTypeConfigurations))
        .reduce((previous, current) => previous + current, 0);

    const hoursTotal = timesheetDays
        .map(d => getTimesheetEntryValues(d, projectTimeType, TimeEntryType.Hours, timeTypeConfigurations))
        .reduce((previous, current) => previous + current, 0)
        .toFixed(3);

    const days = timesheetDays || [];

    return (
        <Segment>
            <Header content="Timesheet Days" dividing textAlign="center" />

            {days.sort(dateSortAsc(t => t.date)).map((day) => (
                <TimesheetDayView
                    key={day.id}
                    day={day}
                    timeTypeConfigurations={timeTypeConfigurations}
                    projectTimeType={projectTimeType}
                    timesheetEntryOptions={timesheetEntryOptions}
                />
            ))}
            <Divider />
            <Header textAlign="right">{`Total time for this week: ${daysTotal} Days, ${hoursTotal} Hours`}</Header>
        </Segment>
    );
};

export { TimesheetDaysView };
