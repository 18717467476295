import { createSelector } from "reselect";

import { Country } from "../model";
import { flatGlobalConfigSelector } from "../globalConfig/selectors";
import { GlobalConfigKeys, GlobalConfigState } from "../globalConfig/model";
import { isNullOrUndefined } from "../typeUtils";

import { ExtractValueType, RegionState } from "./model";
import { LocalTextKey, LocalTextValues, values } from "./values";

export const regionSelector = (state: RegionState) => state.region;

const keyNameSelector = (state: any, keyName: LocalTextKey) => keyName;

export const localTextSelectorUntyped = createSelector(
    regionSelector,
    flatGlobalConfigSelector,
    keyNameSelector,
    (region, serverConfig, keyName) => {

        if (serverConfig[keyName]) {
            return serverConfig[keyName];
        }

        const options = values[keyName];
        const country = Country[region];

        if (isNullOrUndefined(options)) {
            return undefined;
        }

        if (Object.keys(options).some(o => o === country)) {
            return options[country];
        }

        return options.default;
    }
);

export function localTextSelector<K extends GlobalConfigKeys>(state: GlobalConfigState, key: K): string;
export function localTextSelector<K extends LocalTextKey>(state: RegionState, key: K): ExtractValueType<LocalTextValues[K]>;
export function localTextSelector(state: any, key: any): any {
    return localTextSelectorUntyped(state, key);
}

export const localeSelector = () => (window.navigator as any).userLanguage || window.navigator.language;
