import moment from "moment";

import { Timesheet } from "../timesheets";
import { ExpenseTypeConfiguration } from "../hierarchicalConfiguration";

export interface Expense {
    id: number;
    expenseTypeId?: number;
    expenseType?: ExpenseTypeConfiguration;
    timesheetId?: number;
    timesheet?: Timesheet;
    date?: moment.Moment;
    description?: string;
    net?: number;
    vat?: number;
    receiptFile?: string;
    previousReceipt?: boolean;
    previousReceiptExpenseId?: number;
    previousReceiptExpense?: Expense;
    receiptState?: ReceiptState;
    receiptRejectionReason?: string;
    correlationId?: number;
    amount?: number;
}

export enum ReceiptState {
    Pending = 10,
    Approved = 20,
    Rejected = 30
}

export enum ExpenseInputTypeEnum {
    Currency = 0,
    Quantity = 10
}

export class ExpenseBuilder {
    private id: number;
    private expenseTypeId: number;
    private expenseType: ExpenseTypeConfiguration;
    private timesheetId: number;
    private timesheet: Timesheet;
    private date: moment.Moment;
    private description: string;
    private net: number;
    private vat: number;
    private receiptFile: string;
    private previousReceipt: boolean;
    private previousReceiptExpenseId: number;
    private previousReceiptExpense: Expense;
    private receiptState: ReceiptState;
    private amount?: number;

    constructor() {
        this.id = 0;
        this.expenseTypeId = 0;
        this.expenseType = null;
        this.timesheetId = 0;
        this.timesheet = undefined;
        this.date = undefined;
        this.net = 0;
        this.vat = 0;
        this.description = "";
        this.receiptFile = null;
        this.previousReceipt = false;
        this.previousReceiptExpenseId = 0;
        this.previousReceiptExpense = undefined;
        this.receiptState = 0;
        this.amount = 0;
    }

    public withId(id: number): ExpenseBuilder {
        this.id = id;
        return this;
    }

    public withExpenseTypeId(expenseTypeId: number): ExpenseBuilder {
        this.expenseTypeId = expenseTypeId;
        return this;
    }

    public withExpenseType(expenseType: ExpenseTypeConfiguration): ExpenseBuilder {
        this.expenseType = expenseType;
        return this;
    }

    public withTimesheetId(timesheetId: number): ExpenseBuilder {
        this.timesheetId = timesheetId;
        return this;
    }

    public withTimesheet(timesheet: Timesheet): ExpenseBuilder {
        this.timesheet = timesheet;
        return this;
    }

    public withDate(date: moment.Moment): ExpenseBuilder {
        this.date = date;
        return this;
    }

    public withNet(net: number): ExpenseBuilder {
        this.net = net;
        return this;
    }

    public withVat(vat: number): ExpenseBuilder {
        this.vat = vat;
        return this;
    }

    public withDescription(description: string): ExpenseBuilder {
        this.description = description;
        return this;
    }

    public withReceiptFile(receiptFile: string): ExpenseBuilder {
        this.receiptFile = receiptFile;
        return this;
    }

    public withPreviousReceipt(previousReceipt: boolean): ExpenseBuilder {
        this.previousReceipt = previousReceipt;
        return this;
    }

    public withPreviousReceiptExpenseId(previousReceiptExpenseId: number): ExpenseBuilder {
        this.previousReceiptExpenseId = previousReceiptExpenseId;
        return this;
    }

    public withPreviousReceiptExpense(previousReceiptExpense: Expense): ExpenseBuilder {
        this.previousReceiptExpense = previousReceiptExpense;
        return this;
    }

    public withReceiptState(receiptState: number): ExpenseBuilder {
        this.receiptState = receiptState;
        return this;
    }

    public withAmount(amount: number) {
        this.amount = amount;
        return this;
    }

    public build(): Expense {
        return {
            id: this.id,
            expenseTypeId: this.expenseTypeId,
            expenseType: this.expenseType,
            timesheetId: this.timesheetId,
            timesheet: this.timesheet,
            date: this.date,
            vat: this.vat,
            net: this.net,
            description: this.description,
            receiptFile: this.receiptFile,
            previousReceipt: this.previousReceipt,
            previousReceiptExpenseId: this.previousReceiptExpenseId,
            previousReceiptExpense: this.previousReceiptExpense,
            receiptState: this.receiptState,
            amount: this.amount
        };
    }
}

export type ExpensesState = {
    expenses: Expense[]
};
