import { FilterValue } from "./FilterValue";
import { FilterValueType } from "./FilterBuilder";

export const encodeFilterValue = <T>(value: FilterValueType<T>) => {

    if (value === undefined) {
        return "";
    }

    if (value instanceof FilterValue) {
        return value.get();
    }

    if (typeof value === "string" && value !== "") {
        const encoded = encodeURIComponent(value.replace(/'/g, "''"));
        return `'${encoded}'`;
    }

    return `${value}`;
};
