import { LocalTextValue } from "../model";

export const stateAddressFieldEnabled: LocalTextValue<boolean> = {
    default: false,
    Usa: true,
};

export const stateAddressFieldNotEnabled: LocalTextValue<boolean> = {
    default: !stateAddressFieldEnabled.default,
    Usa: !stateAddressFieldEnabled.Usa,
};
