import { createSelector } from "reselect";

import { GlobalConfig, GlobalConfigState } from "./model";

export const globalConfigsSelector = (state: GlobalConfigState) => state.globalConfigs;

export const flatGlobalConfigSelector = createSelector(
    globalConfigsSelector,
    (config: GlobalConfig[]) => config.reduce((flatConfig, value) => ({
        ...flatConfig,
        [value.name]: value.value
    }), {})
);
