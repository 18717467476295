import * as React from "react";
import { connect } from "react-redux";
import { Breadcrumb, Container } from "semantic-ui-react";
import { Link, State as RouterState } from "redux-little-router";

interface BreadcrumbLink {
    name: string;
    href: string;
}

interface RouteInfo {
    title: string | ((state: any) => string);
    route: string;
    parent: RouteInfo;
}

interface BreadcrumbsProps {
    route: RouteInfo;
    params: any;
    state: any;
}

interface DispatchProps {
    dispatch: any;
}

class BreadcrumbsUnconnected extends React.Component<BreadcrumbsProps & DispatchProps, {}> {
    public render() {

        const links = this.getLinks(this.props.route);

        const breadcrumbs = links.map(l => () => {
            if (!l.href) {
                return <Breadcrumb.Section active>{l.name}</Breadcrumb.Section>;
            }

            return <Breadcrumb.Section as={Link} href={l.href}>{l.name}</Breadcrumb.Section>;
        });

        const elements: (() => JSX.Element)[] = breadcrumbs.reduce((acc, item, i, { length }) => {
            if (i && i < length) {
                return [...acc, () => <Breadcrumb.Divider icon="right angle" />, item];
            }
            return [...acc, item];
        }, []);

        const showBreadcrumbs = elements.length > 1;

        return showBreadcrumbs && (
            <Container>
                <Breadcrumb size="tiny">
                    {elements.map((Ele, i) => <Ele key={i} />)}
                </Breadcrumb>
            </Container>
        );
    }

    private getLinks(routeInfo: RouteInfo): BreadcrumbLink[] {

        if (routeInfo === undefined) {
            return [];
        }

        const link = {
            name: this.getTitle(routeInfo.title),
            href: this.getUrl(routeInfo.route)
        };

        if (routeInfo.parent) {
            return [
                ...this.getLinks(routeInfo.parent),
                link
            ];
        }

        return [link];
    }

    private getTitle(title: string | ((state: any) => string)): string {
        if (typeof title === "function") {
            return title(this.props.state);
        }
        return title;
    }

    private getUrl(url: string): string {

        if (!url || url.indexOf(":") === -1) {
            return url;
        }

        const regex = /:(\w+)/g;

        return url.replace(regex, (match, param) => {
            const value = this.props.params[param];
            return value ? value : `:${param}`;
        });
    }
}

function mapStateToProps(state: RouterState): BreadcrumbsProps {
    return {
        params: state.router.params,
        route: state.router.result as RouteInfo,
        state
    };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
    return {
        dispatch
    };
}

export const Breadcrumbs = connect(mapStateToProps, mapDispatchToProps)(BreadcrumbsUnconnected);
