import * as React from "react";
import { connect } from "react-redux";
import { Location, State as RouterState } from "redux-little-router";
import { AppInsights } from "applicationinsights-js";

import { ErrorBoundaryState } from "./ErrorBoundary";
import { ErrorBoundaryContent } from "./ErrorBoundaryContent";

interface ErrorBoundaryRoutedOwnProps {
    children?: React.ReactNode;
}

interface ErrorBoundaryRoutedStateProps {
    location: Location;
}

interface ErrorBoundaryRoutedState extends ErrorBoundaryState {
    location: Location;
}

class ErrorBoundaryRoutedUnconnected extends React.Component<ErrorBoundaryRoutedStateProps & ErrorBoundaryRoutedOwnProps, ErrorBoundaryRoutedState> {

    public static getDerivedStateFromProps(nextProps: ErrorBoundaryRoutedStateProps, state: ErrorBoundaryRoutedState): ErrorBoundaryRoutedState {
        if (nextProps.location === state.location) {
            return null;
        }

        return {
            location: nextProps.location,
            hasError: false,
            errorInfo: undefined
        };

    }

    public state: ErrorBoundaryRoutedState = {
        hasError: false,
        errorInfo: undefined,
        location: undefined
    };

    public componentDidCatch(error: Error, info: React.ErrorInfo) {
        AppInsights.trackException(error, "ErrorBoundary", { componentStack: info.componentStack });

        this.setState({
            hasError: true,
            errorInfo: info
        });
    }

    public render() {
        const { hasError } = this.state;

        if (hasError) {
            return (
                <ErrorBoundaryContent />
            );
        }

        return this.props.children;
    }
}

const mapStateToProps = (state: RouterState): ErrorBoundaryRoutedStateProps => ({
    location: state.router
});

export const ErrorBoundaryRouted = connect(mapStateToProps)(ErrorBoundaryRoutedUnconnected);
