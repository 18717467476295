export function booleanSort<T>(...getProperties: ((obj: T) => boolean)[]) {
    return (a: T, b: T) => {

        for (const getProperty of getProperties) {

            const aProp = getProperty(a);
            const bProp = getProperty(b);

            if (aProp > bProp) {
                return -1;
            } else if (aProp < bProp) {
                return 1;
            }
        }

        return 0;
    };
}
