import * as React from "react";
import { Button, Table } from "semantic-ui-react";

import { ExpenseTypeConfiguration } from "../hierarchicalConfiguration";
import { LocalText } from "../internationalisation/LocalText";
import { Currency } from "../internationalisation";

import { Expense } from "./model";
import { getExpenseNetAndVat } from "./getExpenseNetAndVat";

export interface ExpenseRowViewProps {
    expense: Expense;
    expenseTypeConfigurations: ExpenseTypeConfiguration[];
}

export const ExpenseRowView: React.SFC<ExpenseRowViewProps> = ({ expense, expenseTypeConfigurations }) => {
    const getExpenseReceipt = () => {

        if (expense.receiptFile) {
            return expense.receiptFile;
        }

        if (expense.previousReceipt && expense.previousReceiptExpense) {
            return expense.previousReceiptExpense.previousReceipt;
        }

        return null;
    };

    const receiptDownloadButton = () => {
        const receiptFile = getExpenseReceipt();

        return (
            <Button
                as="a"
                icon="file text"
                href={`/api/receipt/${receiptFile}`}
                target="_blank"
                disabled={receiptFile === null}
            />
        );
    };

    const [net, vat] = getExpenseNetAndVat(expense, expenseTypeConfigurations);

    return (
        <Table.Row>
            <Table.Cell>{expense.date.format("LL")}</Table.Cell>
            <Table.Cell>{expense.description}</Table.Cell>
            <Table.Cell>
                <>
                    <Currency value={net} /> / <LocalText keyName="taxAbbreviation" /> <Currency value={vat} />
                </>
            </Table.Cell>
            <Table.Cell>
                {receiptDownloadButton()}
            </Table.Cell>
        </Table.Row>
    );
};
