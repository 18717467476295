import { ReactNode } from "react";
import { VettingTeamAus, VettingTeamUk, VettingTeamUsa } from "src/App/Associate/src/momentaTeams/VettingTeam";

import { LocalTextValue } from "../../model";

export const vettingInfo: LocalTextValue<ReactNode> = {
    "default": VettingTeamUk,
    "United Kingdom": VettingTeamUk,
    "Australia": VettingTeamAus,
    "Usa": VettingTeamUsa,
};
