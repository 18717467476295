import moment from "moment";

import { Timesheet } from "./model";

const parseDate = (value: any) => value == null ? value : moment(value);
const parseDateUtc = (value: any) => value == null ? value : moment.utc(value);

export const parseTimesheet = (model: Timesheet): Timesheet => {

    return {
        ...model,
        start: parseDateUtc(model.start),
        end: parseDateUtc(model.end),
        bonuses: model.bonuses || [],
        timesheetDays: (model.timesheetDays || []).map(e => ({
            ...e,
            date: parseDateUtc(e.date)
        })),
        expenses: (model.expenses || []).map(e => ({
            ...e,
            date: parseDateUtc(e.date)
        })),
        invoicePeriod: model.invoicePeriod == null ? null : {
            ...model.invoicePeriod,
            start: model.invoicePeriod && parseDateUtc(model.invoicePeriod.start),
            end: model.invoicePeriod && parseDateUtc(model.invoicePeriod.end)
        },
        dateApproved: parseDateUtc(model.dateApproved),
        dateRejected: parseDateUtc(model.dateRejected),
        dateSubmitted: parseDate(model.dateSubmitted),
        originalTimesheet: model.originalTimesheet && parseTimesheet(model.originalTimesheet),
        updated: parseDate(model.updated)
    };
};
