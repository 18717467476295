import { Result, ValidationResult } from "not-valid";

const positiveTimeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
const negativeTimeRegex = /^-?([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

export function positiveTimeValidator(value: string): ValidationResult {
    if (value === undefined) {
        return Result.Stop;
    }

    if (positiveTimeRegex.test(value)) {
        return Result.Pass;
    }

    return Result.Fail("Invalid time");
}

export function negativeTimeValidator(value: string): ValidationResult {
    if (value === undefined) {
        return Result.Stop;
    }

    if (negativeTimeRegex.test(value)) {
        return Result.Pass;
    }

    return Result.Fail("Invalid time");
}
