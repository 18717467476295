import { ValidationState } from "@momenta/common/validationState";

export const validateObject = (valid: ValidationState): boolean => {

    return Object.keys(valid).every(k => {

        const element = valid[k];

        if (element === false) {
            return false;
        }

        if (element instanceof Object === true) {
            return validateObject(element as ValidationState);
        }

        return true;
    });
};
