import * as React from "react";
import { Label, List } from "semantic-ui-react";
import { dateSortDesc } from "@momenta/common";
import { Link } from "redux-little-router";
import { isNotNullOrUndefined } from "@momenta/common/typeUtils";

import { getEndDateTimesheet } from "./getEndDateTimesheet";
import { Timesheet, TimesheetStateEnum } from "./model";

export interface TimesheetListProps {
    timesheets: Timesheet[];
    basePath?: string;
    saving?: boolean;
    userCanApproveOrReject?: boolean;
    onApproved?: (timesheetId: number) => void;
    onRejected?: (timesheetId: number) => void;
}

export const TimesheetList: React.FC<TimesheetListProps> = ({ timesheets, basePath }) => {

    const timesheetState = (state: TimesheetStateEnum) => {

        switch (state) {
            case TimesheetStateEnum.Approved:
                return <Label content="Approved" icon="check" color="green" size="tiny" />;
            case TimesheetStateEnum.Submitted:
                return <Label content="Submitted" icon="check" color="blue" size="tiny" />;
            case TimesheetStateEnum.Rejected:
                return <Label content="Rejected" icon="edit" color="red" size="tiny" />;
            default:
                return <Label content="Incomplete" icon="warning" color="yellow" size="tiny" />;
        }
    };

    const adjustmentTimesheetLabel = () => {
        return <Label content="Adjustment" icon="newspaper outline" color="blue" size="tiny" />;
    };

    const getTimesheetEndDate = (timesheet: Timesheet) => {
        return isNotNullOrUndefined(timesheet.end) ? timesheet.end.format("ll") : getEndDateTimesheet(timesheet).format("ll");
    };

    const dateSortedTimesheets = timesheets && timesheets.sort(dateSortDesc(c => c.start));

    return (
        <List size="tiny" divided relaxed selection className="timesheet-list">
            {dateSortedTimesheets?.map((timesheet, index) => (
                <List.Item as={Link} href={`${basePath || ""}/timesheets/${timesheet.id}`} key={index}>
                    <List.Content floated="right">
                        {timesheet.isAdjusted && adjustmentTimesheetLabel()}
                        {timesheetState(timesheet.state)}
                    </List.Content>

                    <List.Icon name="calendar outline" size="big" verticalAlign="middle" />

                    <List.Content>
                        <List.Header>
                            {timesheet.start.format("ll")} - {getTimesheetEndDate(timesheet)}
                        </List.Header>
                        <List.Description>
                            {timesheet.invoicePeriod.candidate.role.project.name}
                            <br />{timesheet.invoicePeriod.candidate.role.title}
                        </List.Description>
                    </List.Content>
                </List.Item>
            ))}
        </List>
    );
};
