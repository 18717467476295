import * as React from "react";
import { BonusTypeConfiguration, ExpenseTypeConfiguration, TimeTypeConfiguration } from "@momenta/common/hierarchicalConfiguration";
import { DropdownItemProps } from "semantic-ui-react";

import { BonusesView } from "../bonuses/BonusesView";
import { ExpensesView } from "../expenses/ExpensesView";

import { TimesheetDaysView } from "./TimesheetDaysView";
import { Timesheet, timesheetEntryOptions } from "./model";

interface TimesheetViewProps {
    timesheet: Timesheet;
    timeTypeConfigurations: TimeTypeConfiguration[];
    bonusTypeConfigurations: BonusTypeConfiguration[];
    expenseTypeConfigurations: ExpenseTypeConfiguration[];
    negativeTimesheetEntryOptions?: DropdownItemProps[];
}

export const TimesheetView: React.SFC<TimesheetViewProps> = (props) => {

    const { timesheet, timeTypeConfigurations, bonusTypeConfigurations, expenseTypeConfigurations, negativeTimesheetEntryOptions } = props;

    return (
        <>
            {timesheet.timesheetDays &&
                <TimesheetDaysView
                    timesheetDays={timesheet.timesheetDays}
                    timeTypeConfigurations={timeTypeConfigurations}
                    projectTimeType={timesheet && timesheet.invoicePeriod.candidate.role.project.timeEntryType}
                    timesheetEntryOptions={negativeTimesheetEntryOptions || timesheetEntryOptions}
                />
            }

            {bonusTypeConfigurations.length !== 0 && timesheet && timesheet.bonuses && (
                <BonusesView
                    bonuses={timesheet.bonuses}
                    bonusTypeConfigurations={bonusTypeConfigurations}
                />
            )}

            {expenseTypeConfigurations.length !== 0 && timesheet.expenses && (
                <ExpensesView
                    timesheetId={timesheet.id}
                    start={timesheet && timesheet.start}
                    expenses={timesheet.expenses}
                    expenseTypeConfigurations={expenseTypeConfigurations}
                />
            )}
        </>
    );
};
