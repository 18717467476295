import moment from "moment";
import { createSelector } from "reselect";

import { TypeConfiguration } from "../hierarchicalConfiguration/model";

interface ConfigOptionState<T extends TypeConfiguration<any>> {
    config: T[];
    date: moment.Moment;
}

const getConfig = <T extends TypeConfiguration<any>>(state: ConfigOptionState<T>) => state.config;
const getDate = <T extends TypeConfiguration<any>>(state: ConfigOptionState<T>) => state.date;

const sortConfig = <T extends TypeConfiguration<any>>(a: T, b: T) => {

    const aDeletedValue = a.deleted ? 1 : 0;
    const bDeletedValue = b.deleted ? 1 : 0;

    const diff = aDeletedValue - bDeletedValue;

    if (diff !== 0) {
        return diff;
    }

    return b.specificity - a.specificity;
};

export const dateConfigOptionSelector = createSelector(
    getConfig,
    getDate,
    <T extends TypeConfiguration<any>>(config: T[], date: moment.Moment) => {

        const todaysConfig = config.filter(t => t.start.isSameOrBefore(date) && (t.end === null || t.end.isSameOrAfter(date)));

        const groupedByType = todaysConfig
            .reduce<{ [key: string]: T[] }>((result, item) => {
                result[item.typeId] = result[item.typeId] || [];
                result[item.typeId].push(item);
                return result;
            }, {});

        const mostSpecificWithoutDeleted = Object.keys(groupedByType)
            .reduce((result, index) => {

                const nonDeletesTypes = groupedByType[index]
                    .filter(item => item.deletesId === null);

                if (nonDeletesTypes.length === 0) {
                    return result;
                }

                const mostSpecificType = nonDeletesTypes
                    .sort(sortConfig)[0];

                return [...result, mostSpecificType];
            }, []);

        return mostSpecificWithoutDeleted.map(t => ({
            text: t.type.name,
            value: t.id
        }));
    }
);
