import * as React from "react";
import { Button, Form, Label } from "semantic-ui-react";

import { isNullOrUndefined } from ".";

export interface YesNoOptionProps {
    label: string;
    propValue: boolean;
    onChange: (value: boolean, valid?: boolean) => void;
    required?: boolean;
    showErrors?: boolean;
    errorMessage?: string;
}

const YesNoOption: React.FC<YesNoOptionProps> = ({ label, propValue, onChange, required, showErrors, errorMessage }) => {
    const showRequiredWarning = isNullOrUndefined(propValue) && required && showErrors;
    const labelMessage = errorMessage ?? "This field is required";
    const yesClicked = () => { onChange(true, true); };
    const noClicked = () => { onChange(false, true); };
    return (
        <>
            <Form.Field>
                <label>{label}</label>
                <Button.Group size="small">
                    <Button type="button" content="Yes" active={propValue === true} onClick={yesClicked} />
                    <Button type="button" content="No" active={propValue === false} onClick={noClicked} />
                </Button.Group>
            </Form.Field>
            { showRequiredWarning &&
                <Label className="ui red pointing above basic label" pointing="above">{labelMessage}</Label>
            }
        </>
    );
};

export {
    YesNoOption
};
