import { validate, ValidationFunction, ValidationOptions } from "not-valid";

export class InputValidator<TValue> {

    private validators: ValidationFunction<TValue>[];
    private options: ValidationOptions;

    constructor(validators?: ValidationFunction<TValue>[], options?: ValidationOptions) {

        this.validators = (validators === null || validators === undefined)
            ? []
            : validators;

        this.options = options;
    }

    public validate(value: TValue): Promise<string[]> {
        return validate(this.validators, value, this.options);
    }
}
