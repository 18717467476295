import { AppInsights } from "applicationinsights-js";
import { Store } from "redux";

export const enrichLogsWithUser = (store: Store<any>) => AppInsights.queue.push(() => {
    AppInsights.context.addTelemetryInitializer(envelope => {
        try {
            const telemetryItem = envelope.data.baseData;
            telemetryItem.properties = telemetryItem.properties || {};
            telemetryItem.properties.userId = store.getState().currentUser.id;
            telemetryItem.properties.userEmail = store.getState().currentUser.email;
        } catch {
            // Swallow any error here, as we don't want to not log anything because we have failed to enrich with the user id
        }
    });
});
