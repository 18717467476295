import * as React from "react";

export const FinanceTeamUk = (<>
    <p>Finance Team</p>
    <p>Tel: 0207 374 5600</p>
    <p>Email: <a className="momenta-team-link" href="mailto:invoices@momentagroup.com">invoices@momentagroup.com</a></p>
    <p>
        Once you have started your new role, our Finance Team will manage your timesheets and invoicing process, ensuring
        that you are paid correctly, on time and without any issues. They can help you with:
    </p>
    <ul>
        <li>Invoices and payments</li>
        <li>Timesheets for current Momenta associates</li>
    </ul>
</>);

export const FinanceTeamAus = (<>
    <p>Finance Team</p>
    <p>Tel: 028871 8804</p>
    <p>Email: <a className="momenta-team-link" href="mailto:admin@momentaresourcing.com.au">admin@momentaresourcing.com.au</a></p>
    <p>Once you have started your new role, our Finance Team will manage your timesheets process, ensuring that you are paid
         correctly, on time and without any issues. They can help you with:
    </p>
    <ul>
        <li>Payments</li>
        <li>Timesheets for current Momenta associates</li>
    </ul>
</>);

export const FinanceTeamUsa = (<>
    <p>Finance Team</p>
    <p>Tel: +1 917 781 4652 (Option 2)</p>
    <p>Email: <a className="momenta-team-link" href="mailto:usassociates@momentagroup.com">usassociates@momentagroup.com</a></p>
    <p>
        Once you have started your new role, our Finance Team will manage your timesheets and invoicing process, ensuring
        that you are paid correctly, on time and without any issues. They can help you with:
    </p>
    <ul>
        <li>Invoices and payments</li>
        <li>Timesheets for current Momenta associates</li>
    </ul>
</>);
