import { Action, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { LOCATION_CHANGED } from "redux-little-router";
import { AppInsights } from "applicationinsights-js";

import { getContext } from "./getContext";

export const appInsightsMiddleware: Middleware = (api: MiddlewareAPI<any>) => (next: Dispatch<Action>) => async (action: any) => {
    const result = await next(action);

    if (action.type === LOCATION_CHANGED) {
        const routeResult = (action.payload || {}).result;

        if (routeResult) {
            const router = api.getState().router;
            AppInsights.trackPageView(router.pathname, null, getContext());
        }
    }
    return result;
};
