import { showClientServicesCard } from "./showClientServicesCard";
import { clientServicesInfo } from "./clientServicesInfo";
import { financeInfo } from "./financeInfo";
import { resourcingInfo } from "./resourcingInfo";
import { vettingInfo } from "./vettingInfo";
import { showMomentaTeamsCard } from "./showMomentaTeamsCard";

export const momentaTeamsInfo = {
    showClientServicesCard,
    clientServicesInfo,
    financeInfo,
    resourcingInfo,
    vettingInfo,
    showMomentaTeamsCard,
};
