import * as React from "react";
import { Header, Message } from "semantic-ui-react";

import { BonusTypeConfiguration, ExpenseTypeConfiguration, TimeTypeConfiguration } from "../hierarchicalConfiguration";

import { TimesheetView } from "./TimesheetView";
import { negativeTimesheetEntryOptions, Timesheet } from "./model";

interface TimesheetReadonlyViewProps {
    dateRange: string;
    timesheet: Timesheet;
    timeTypeConfigurations: TimeTypeConfiguration[];
    bonusTypeConfigurations: BonusTypeConfiguration[];
    expenseTypeConfigurations: ExpenseTypeConfiguration[];
}

const getAssociateName = (timesheet: Timesheet) =>
    timesheet.invoicePeriod
    && timesheet.invoicePeriod.candidate
    && timesheet.invoicePeriod.candidate.associate
    && timesheet.invoicePeriod.candidate.associate.fullName;

export const TimesheetViews: React.FC<TimesheetReadonlyViewProps> =
({ dateRange, timesheet, timeTypeConfigurations, bonusTypeConfigurations, expenseTypeConfigurations }) => (
    <>
        <Header as="h1">
            {dateRange}
            <Header.Subheader>{getAssociateName(timesheet)}</Header.Subheader>
        </Header>

        {timesheet.isAdjusted &&
            <Message success size="big">
                <Header content="Original Timesheet" textAlign="center" />
            </Message>
        }

        <TimesheetView
            timesheet={timesheet.isAdjusted ? timesheet.originalTimesheet : timesheet}
            timeTypeConfigurations={timeTypeConfigurations}
            bonusTypeConfigurations={bonusTypeConfigurations}
            expenseTypeConfigurations={expenseTypeConfigurations}
        />

        {timesheet.isAdjusted &&
            <>
                <Message warning size="big">
                    <Header content="Adjustment Timesheet" textAlign="center" />
                </Message>

                <TimesheetView
                    timesheet={timesheet}
                    timeTypeConfigurations={timeTypeConfigurations}
                    bonusTypeConfigurations={bonusTypeConfigurations}
                    expenseTypeConfigurations={expenseTypeConfigurations}
                    negativeTimesheetEntryOptions={negativeTimesheetEntryOptions}
                />
            </>
        }
    </>
);
