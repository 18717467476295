import * as React from "react";
import { Button } from "semantic-ui-react";

import Modal from "./Modal";

type ButtonStyle = "negative" | "positive" | "default";

export interface ConfirmModalProps {
    title: string;
    content?: React.ReactNode;
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    size?: "fullscreen" | "large" | "mini" | "small" | "tiny";
    noStyle?: ButtonStyle;
    yesStyle?: ButtonStyle;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ title, content, open, size, onCancel, onConfirm, noStyle, yesStyle }) => {
    const getButtonStyle = (style: ButtonStyle, defaultStyle: ButtonStyle) => ({ [style ?? defaultStyle]: true });

    return (
        <Modal size={size || "mini"} open={open}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>{content || <p>Are you sure?</p>}</Modal.Content>
            <Modal.Actions>
                <Button {...getButtonStyle(noStyle, "negative")} content="No" onClick={onCancel} />
                <Button {...getButtonStyle(yesStyle, "positive")} content="Yes" onClick={onConfirm} />
            </Modal.Actions>
        </Modal>
    );
};

export default ConfirmModal;
