import * as React from "react";
import { Container, Header, Icon, Message } from "semantic-ui-react";

export const ErrorBoundaryContent: React.FC<{}> = () => (
    <Container textAlign="center">
        <Header as="h1" color="red" textAlign="center" icon>
            <Icon name="warning sign" />
            <Header.Content>Error</Header.Content>
        </Header>

        <Message negative compact>
            <Message.Header>Something has gone wrong</Message.Header>
            <p>Try going back to the <a href="/">home page</a></p>
        </Message>
    </Container>
);
