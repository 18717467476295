import * as React from "react";
import { Button, DropdownItemProps, Form, Grid, Header, List, Segment } from "semantic-ui-react";
import { ValidationState } from "@momenta/common/validationState";

import { TimeEntryType, TimeTypeConfiguration } from "../hierarchicalConfiguration";
import { validateObject } from "../validateObject";

import { TimesheetEntryEdit } from "./TimesheetEntryEdit";
import { TimesheetDay, TimesheetEntry } from "./model";

interface TimesheetDayEditProps {
    day: TimesheetDay;
    timeTypeConfigurations: TimeTypeConfiguration[];
    timesheetDayUpdated: (timesheetDay: TimesheetDay, valid: ValidationState) => void;
    projectTimeType: TimeEntryType;
    submitted: boolean;
    timesheetDayValid: ValidationState;
    showErrors: boolean;
    timesheetEntryOptions: DropdownItemProps[];
    isAdjustment: boolean;
    workedHoursRequired: boolean;
}

export const TimesheetDayEdit: React.SFC<TimesheetDayEditProps> = ({
    day,
    timeTypeConfigurations,
    timesheetDayUpdated,
    projectTimeType,
    submitted,
    timesheetDayValid,
    showErrors,
    timesheetEntryOptions,
    isAdjustment,
    workedHoursRequired
}) => {

    const addTimeType = (event: React.MouseEvent<HTMLButtonElement>) => {

        event.stopPropagation();
        event.preventDefault();

        const timesheetEntries = [
            ...day.timesheetEntries,
            {
                id: 0,
                timeTypeId: null,
                amount: 0,
                timeType: null,
                hoursMinutes: ""
            }
        ];

        const key = timesheetEntries.length - 1;

        const entriesValid = timesheetDayValid.timesheetEntries as ValidationState;

        const valid = {
            ...entriesValid,
            [key]: {
                timeTypeId: false,
                amount: true
            }
        };
        updateEntries(timesheetEntries, valid);
    };

    const onChange = (index: number, entry: TimesheetEntry, valid: ValidationState) => {
        const entries = [...day.timesheetEntries];
        entries.splice(index, 1, entry);
        const entriesValid = {
            ...(timesheetDayValid.timesheetEntries as ValidationState),
            [index]: valid
        };
        updateEntries(entries, entriesValid);
    };

    const onDelete = (index: number) => {
        const entries = [...day.timesheetEntries];
        const validTimesheetEntries = { ...(timesheetDayValid.timesheetEntries as ValidationState) };
        entries.splice(index, 1);
        delete validTimesheetEntries[index];
        updateEntries(entries, validTimesheetEntries);
    };

    const updateEntries = (entries: TimesheetEntry[], valid: ValidationState) => {

        const updatedDay = {
            ...day,
            timesheetEntries: entries
        };

        const updatedValid = {
            ...timesheetDayValid,
            timesheetEntries: valid
        };

        timesheetDayUpdated(updatedDay, updatedValid);
    };

    const createAddTimeEntryButton = () => {
        return (
            <Button
                basic
                floated="right"
                icon="add"
                content="Add Time Entry"
                onClick={addTimeType}
                disabled={submitted}
                color="green"
            />
        );
    };

    const timesheetEntriesValid = timesheetDayValid && timesheetDayValid.timesheetEntries as ValidationState;

    const filteredConfigurations = (index: number): TimeTypeConfiguration[] => {
        const otherEntries = day.timesheetEntries.filter((e, i) => i !== index).map(e => e.timeTypeId);
        const otherConfigs = timeTypeConfigurations.filter(t => !otherEntries.some(typeId => typeId === t.id));
        return otherConfigs;
    };

    const allConfigsAssigned = () => {
        const assignedConfigs = day.timesheetEntries.map(e => e.timeTypeId);
        return timeTypeConfigurations.every(t => assignedConfigs.some(typeId => typeId === t.id));
    };

    const isValid = (): boolean => {
        return validateObject(timesheetEntriesValid || {});
    };

    const getOnChange = (index: number) => { return (newEntry: TimesheetEntry, valid: ValidationState) => onChange(index, newEntry, valid); };
    const getDelete = (index: number) => { return () => onDelete(index); };

    return (
        <>
            <Header attached="top">
                <h3>{day.date.format("dddd, MMMM Do YYYY")}</h3>
            </Header>
            <Segment attached="bottom">
                <List divided className="timeentry-list">
                    {day.timesheetEntries.length === 0 &&
                        <Grid>
                            <Grid.Column mobile={16} computer={13}>
                                <p className="disabled-text">You currently have no entries listed for this day</p>
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={3}>
                                {createAddTimeEntryButton()}
                            </Grid.Column>
                        </Grid>
                    }
                    {day.timesheetEntries.map((entry, index) => (
                        <List.Item key={index}>
                            <Grid>
                                <TimesheetEntryEdit
                                    key={index}
                                    timesheetEntryValid={(timesheetEntriesValid && timesheetEntriesValid[index] || {}) as ValidationState}
                                    timesheetEntry={entry}
                                    timeTypeConfigurations={filteredConfigurations(index)}
                                    onChange={getOnChange(index)}
                                    onDelete={getDelete(index)}
                                    projectTimeType={projectTimeType}
                                    submitted={submitted}
                                    showErrors={showErrors}
                                    timesheetEntryOptions={timesheetEntryOptions}
                                    isAdjustment={isAdjustment}
                                    workedHoursRequired={workedHoursRequired}
                                    date={day.date}
                                />
                                {index === day.timesheetEntries.length - 1 && isValid() &&
                                    <Grid.Column mobile={16} computer={3} floated="right">
                                        <Form.Field>
                                            <label className="empty-label">&nbsp;</label>
                                            {!allConfigsAssigned() && createAddTimeEntryButton()}
                                        </Form.Field>
                                    </Grid.Column>
                                }
                            </Grid>
                        </List.Item>
                    ))}
                </List>
            </Segment>
        </>
    );
};
