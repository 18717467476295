export interface GlobalConfig {
    readonly id: number;
    readonly name?: string;
    readonly value?: string;
    readonly type?: GlobalConfigType;
}

export enum GlobalConfigType {
    Text = 0,
    Flag = 1
}

export interface GlobalConfigState {
    globalConfigs: GlobalConfig[];
}

export type GlobalConfigKeys = "Refer a friend"
    | "Refer a friend link"
    | "Show refer a friend text"
    | "Show refer a friend text link"
    | never;
