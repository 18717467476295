import { TimeEntryType, TimeTypeConfiguration } from "../hierarchicalConfiguration/model";

import { TimesheetDay } from "./model";

export const getTimesheetEntryValues =
    (day: TimesheetDay, timeType: TimeEntryType, typeDiscriminator: TimeEntryType, timeTypeConfigurations: TimeTypeConfiguration[]) => {

        const totalDays = day.timesheetEntries.map(e => {

            const selectedType = timeTypeConfigurations.filter(t => t.id === e.timeTypeId && t.type && t.type.accountableHours)[0];

            if (selectedType && selectedType.type.timeEntryType === TimeEntryType.Any && timeType === typeDiscriminator) {
                return e.amount;
            }

            if (selectedType && selectedType.type.timeEntryType === typeDiscriminator) {
                return e.amount;
            }

            return 0;
        });

        return totalDays.reduce((previous, current) => previous + current, 0);
    };
