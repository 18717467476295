import { TimeEntryType, TimeTypeConfiguration } from "../hierarchicalConfiguration/model";

import { getTimesheetEntryValues } from "./getTimesheetEntryValues";

import { TimesheetDay } from ".";

export const getTimesheetTotalTimeMessage =
    (timesheetDays: TimesheetDay[], projectTimeType: TimeEntryType, timeTypeConfigurations: TimeTypeConfiguration[]): string => {

        const totalDays = timesheetDays
            .map(d => getTimesheetEntryValues(d, projectTimeType, TimeEntryType.Days, timeTypeConfigurations))
            .reduce((previous, current) => previous + current, 0);

        const totalHours = timesheetDays
            .map(d => getTimesheetEntryValues(d, projectTimeType, TimeEntryType.Hours, timeTypeConfigurations))
            .reduce((previous, current) => previous + current, 0);

        const totals = [];

        if (totalDays !== 0) {
            totals.push(`${totalDays} days`);
        }

        if (totalHours !== 0) {
            totals.push(`${totalHours.toFixed(3)} hours`);
        }

        const totalTimeMessage = totals.length === 0
            ? "No time for this week"
            : `Total time for this week is ${totals.join(" and ")}`;

        return totalTimeMessage;
    };
