import * as React from "react";

export const ClientServicesTeamUk = (<>
    <p>Client Services Team</p>
    <p>Tel: 0207 374 6600 (Option 3)</p>
    <p>Email: <a className="momenta-team-link" href="mailto:admin@momentagroup.com">admin@momentagroup.com</a></p>
    <p>
        Once you have been offered a role with Momenta, our Client Services team will ensure you receive your contract
        and all the details you need prior to starting your new job. They will maintain regular contact with you during your contract assignment and
        can assist with queries about:
    </p>
    <ul>
        <li>Your contract for services with Momenta</li>
        <li>Your current Momenta project</li>
        <li>Reference requests from employers</li>
        <li>Tenancy/Mortgage related references</li>
    </ul>
</>);

export const ClientServicesTeamUsa = (<>
    <p>Client Services Team</p>
    <p>Tel: +1 917 781 4652 (Option 2)</p>
    <p>Email: <a className="momenta-team-link" href="mailto:usassociates@momentagroup.com">usassociates@momentagroup.com</a></p>
    <p>
        Once you have been offered a role with Momenta, our Client Services team will ensure you receive your contract and all the details you need prior to
        starting your new job. Once you are onboarded by Momenta and working for one of our clients - should you need anything at all - contact this
        number/email: They will maintain regular contact with you during your contract assignment and can assist with queries about:
    </p>
    <ul>
        <li>Your contract for services with Momenta</li>
        <li>Your current Momenta project</li>
        <li>Reference requests from employers</li>
        <li>Issues on the job:  Training, manager, systems etc</li>
    </ul>
</>);
