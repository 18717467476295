import * as React from "react";
import { Divider, DropdownItemProps } from "semantic-ui-react";
import { dateSortAsc } from "@momenta/common";
import { ValidationState } from "@momenta/common/validationState";

import { TimeEntryType, TimeTypeConfiguration } from "../hierarchicalConfiguration";

import { TimesheetDay } from "./model";
import { TimesheetDayEdit } from "./TimesheetDayEdit";
import { getTimesheetTotalTimeMessage } from "./getTimesheetTotalTimeMessage";

interface TimesheetDaysProps {
    timesheetDays: TimesheetDay[];
    timeTypeConfigurations: TimeTypeConfiguration[];
    projectTimeType: TimeEntryType;
    submitted: boolean;
    daysUpdated: (timesheetDays: TimesheetDay[], timesheetDayValid: ValidationState) => void;
    showErrors: boolean;
    timesheetDaysValid: ValidationState;
    timesheetEntryOptions: DropdownItemProps[];
    isAdjustment: boolean;
    workedHoursRequired: boolean;
}

const TimesheetDaysEdit: React.FC<TimesheetDaysProps> = ({
    timesheetDays,
    timeTypeConfigurations,
    projectTimeType,
    daysUpdated,
    submitted,
    timesheetDaysValid,
    showErrors,
    timesheetEntryOptions,
    isAdjustment,
    workedHoursRequired
}) => {

    const updateDays = (index: number, newDay: TimesheetDay, valid: ValidationState) => {
        const days = [...timesheetDays];
        const newTimesheetDaysValid = { ...timesheetDaysValid };
        days.splice(index, 1, newDay);
        newTimesheetDaysValid[index] = valid;
        daysUpdated(days, newTimesheetDaysValid);
    };

    const totalTimeMessage = getTimesheetTotalTimeMessage(timesheetDays, projectTimeType, timeTypeConfigurations);
    const getTimesheetDayUpdated = (index: number) => { return (newDay: TimesheetDay, valid: ValidationState) => updateDays(index, newDay, valid); };

    return (
        <>
            {(timesheetDays || []).sort(dateSortAsc(t => t.date)).map((day, index) => (
                <TimesheetDayEdit
                    key={index}
                    day={day}
                    timesheetDayValid={(timesheetDaysValid && timesheetDaysValid[index] || {}) as ValidationState}
                    timeTypeConfigurations={timeTypeConfigurations}
                    timesheetDayUpdated={getTimesheetDayUpdated(index)}
                    projectTimeType={projectTimeType}
                    submitted={submitted}
                    showErrors={showErrors}
                    timesheetEntryOptions={timesheetEntryOptions}
                    isAdjustment={isAdjustment}
                    workedHoursRequired={workedHoursRequired}
                />
            ))}
            <Divider />
            <h3 className="time-total">
                {totalTimeMessage}
            </h3>
            <Divider />
        </>
    );
};

export { TimesheetDaysEdit };
