import { Country } from "@momenta/common/model";

import { RegionAction } from "./actions";
import { SAVE_REGION_SUCCESS } from "./actiontypes";

const defaultCountry = Country["United Kingdom"];

export function region(state: Country = defaultCountry, action: RegionAction): Country {
    switch (action.type) {
        case SAVE_REGION_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
