import * as React from "react";
import moment from "moment";
import { Grid, Header, Segment, Table } from "semantic-ui-react";

import { ExpenseTypeConfiguration } from "../hierarchicalConfiguration";
import {  Currency, LocalText } from "../internationalisation";

import { Expense, ExpenseInputTypeEnum } from "./model";
import { ExpenseRowView } from "./ExpenseRowView";

export interface ExpensesViewProps {
    timesheetId: number;
    start: moment.Moment;
    expenses: Expense[];
    expenseTypeConfigurations: ExpenseTypeConfiguration[];
}

export class ExpensesView extends React.Component<ExpensesViewProps> {

    constructor(props: ExpensesViewProps) {
        super(props);

        this.totalExpenses = this.totalExpenses.bind(this);
        this.totalVat = this.totalVat.bind(this);
    }

    public render() {
        return (
            <Segment>
                <Grid>
                    <Grid.Row column={16}>
                        <Grid.Column width={16} textAlign="center">
                            <Header content="Expenses" dividing />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>

                            <Table basic="very" celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        <Table.HeaderCell>Description</Table.HeaderCell>
                                        <Table.HeaderCell>Net / <LocalText keyName="taxAbbreviation" /></Table.HeaderCell>
                                        <Table.HeaderCell>Receipt</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.props.expenses.map((expense, index) =>
                                        (<ExpenseRowView
                                            key={index}
                                            expense={expense}
                                            expenseTypeConfigurations={this.props.expenseTypeConfigurations}
                                        />)
                                    )}

                                    {
                                        this.props.expenses && this.props.expenses.length === 0 &&
                                        <Table.Row>
                                            <Table.Cell colSpan="2" textAlign="center">There are no expenses listed for this timesheet</Table.Cell>
                                        </Table.Row>
                                    }
                                </Table.Body>

                                <Table.Footer>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan="4" textAlign="right">
                                            <h3>
                                                Total value of expenses:
                                                Net <Currency value={this.totalExpenses()} />
                                                / <LocalText keyName="taxAbbreviation" /> <Currency value={this.totalVat()} />
                                            </h3>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }

    private totalExpenses() {

        const { expenses, expenseTypeConfigurations } = this.props;

        let sum = 0;
        for (const expense of expenses) {
            const expenseConfig = expenseTypeConfigurations && expenseTypeConfigurations.filter(c => c.id === expense.expenseTypeId)[0];
            const isQuantity = expenseConfig && expenseConfig.type.expenseInputType === ExpenseInputTypeEnum.Quantity;
            sum += isQuantity ? expense.amount * expenseConfig.rate : expense.net;
        }

        return sum;
    }

    private totalVat() {
        let sum = 0;
        for (const expense of this.props.expenses) {
            sum += expense.vat;
        }
        return sum;
    }
}
