import { Modal as SemanticModal, ModalProps } from "semantic-ui-react";
import * as React from "react";

export interface ModalComponent extends React.SFC<ModalProps> {
    Actions?: typeof SemanticModal.Actions;
    Content?: typeof SemanticModal.Content;
    Description?: typeof SemanticModal.Description;
    Header?: typeof SemanticModal.Header;
}

const Modal: ModalComponent = (props) => (
    <SemanticModal
        mountNode={document.getElementById("approot")}
        {...props}
    />
);

Modal.Actions = SemanticModal.Actions;
Modal.Content = SemanticModal.Content;
Modal.Description = SemanticModal.Description;
Modal.Header = SemanticModal.Header;

export default Modal;
