import * as React from "react";

export const getFormattedMessage = (text: string) => {
    if (!text) {
        return [];
    }
    const splitText = text.split("\n");
    const rows = splitText.map((rowMessage, i) => <p key={i}>{rowMessage}</p>);
    return rows;
};
