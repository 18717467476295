import * as React from "react";
import { Table } from "semantic-ui-react";
import { Result, ValidationFunction } from "not-valid";

import { BonusTypeConfiguration } from "../hierarchicalConfiguration";
import { Currency } from "../internationalisation";

import { Bonus } from "./model";

interface BonusEntriesViewProps {
    bonus: Bonus;
    bonusTypeConfigurations: BonusTypeConfiguration[];
}

export const bonusValidation = (bonusTypeConfigurations: BonusTypeConfiguration[], bonus: Bonus): ValidationFunction<number> => (value: number) => {
    const bonusTypes = bonusTypeConfigurations.filter(e => e.id === bonus.bonusTypeId);

    if (bonusTypes.length === 0) {
        return Result.Stop;
    }

    const maxValue = bonusTypes[0].bonusLimit;

    if (value > maxValue) {
        return Result.Fail(`Total bonus cannot exceed limit of ${maxValue}`);
    }
    return Result.Pass;
};

export const BonusEntriesView: React.SFC<BonusEntriesViewProps> = ({ bonus, bonusTypeConfigurations }) => {
    const typeOptions = bonusTypeConfigurations.map(t => ({
        text: t.type.name,
        value: t.id
    }));

    const selectedType = typeOptions.filter(t => t.value === bonus.bonusTypeId)[0];

    return (
        <Table.Row>
            <Table.Cell>{selectedType && selectedType.text}</Table.Cell>
            <Table.Cell><Currency value={bonus.amount} /></Table.Cell>
        </Table.Row>
    );
};
