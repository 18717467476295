import * as React from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";

export interface RejectModel {
    rejectionReason: string;
}

export interface RejectTimesheetModalProps {
    open: boolean;
    save: (model: RejectModel) => void;
    close: () => void;
}

export const RejectTimesheetModal: React.FC<RejectTimesheetModalProps> = ({ open, save, close }) => {

    const [values, setValues] = React.useState<RejectModel>();
    const [isValid, setValid] = React.useState<boolean>(false);
    const [showErrors, setShowErrors] = React.useState<boolean>(false);

    const submit = () => {
        if (isValid === false) {
            setShowErrors(true);
            return;
        }

        save(values);
    };

    const onRejectReasonChange = (value: string, valid: boolean) => {
        setValid(valid);
        setValues({ rejectionReason: value });
    };

    return (
        <Modal size="small" open={open} onClose={close}>
            <Modal.Header>Reject Timesheet</Modal.Header>
            <Modal.Content>
                <Form onSubmit={submit} noValidate>
                    <Input.Text
                        value={values?.rejectionReason}
                        label="Rejection Reason"
                        showErrors={showErrors}
                        required
                        onChange={onRejectReasonChange}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close} content="Cancel" icon="cancel" color="red" basic />
                <Button onClick={submit} content="Save" icon="save outline" color="green" />
            </Modal.Actions>
        </Modal>
    );
};
