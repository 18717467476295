import * as React from "react";
import { Grid, Header, Segment, Table } from "semantic-ui-react";

import { BonusTypeConfiguration } from "../hierarchicalConfiguration";
import { Currency } from "../internationalisation";

import { BonusEntriesView } from "./BonusEntriesView";
import { Bonus } from "./model";

interface BonusesViewProps {
    bonuses: Bonus[];
    bonusTypeConfigurations: BonusTypeConfiguration[];
}

export class BonusesView extends React.Component<BonusesViewProps, {}> {
    constructor(props: BonusesViewProps) {
        super(props);
    }

    public render() {
        const { bonuses, bonusTypeConfigurations } = this.props;
        const bonusTotals = bonuses.map(b => b.amount).reduce((previous, current) => previous + current, 0);

        return (
            <Segment>
                <Grid>
                    <Grid.Row column={16}>
                        <Grid.Column mobile={16} computer={16} textAlign="center">
                            <Header content="Bonuses" dividing />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row column={16}>
                        <Grid.Column mobile={16} computer={16}>

                            <Table basic="very" celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Type</Table.HeaderCell>
                                        <Table.HeaderCell>Amount</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {(bonuses || []).map((bonus, index) =>
                                        (<BonusEntriesView
                                            key={index}
                                            bonusTypeConfigurations={bonusTypeConfigurations}
                                            bonus={bonus}
                                        />)
                                    )}
                                    {
                                        bonuses && bonuses.length === 0 &&
                                        <Table.Row>
                                            <Table.Cell colSpan="2" textAlign="center">There are no bonuses listed for this timesheet</Table.Cell>
                                        </Table.Row>
                                    }
                                </Table.Body>

                                <Table.Footer>
                                    <Table.Row>
                                        <Table.HeaderCell colSpan="2" textAlign="right">
                                            <h3>Total value of bonuses: <Currency value={bonusTotals} /></h3>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
}
