import { ReactNode } from "react";
import { ClientServicesTeamUk, ClientServicesTeamUsa } from "src/App/Associate/src/momentaTeams/ClientServicesTeam";

import { LocalTextValue } from "../../model";

export const clientServicesInfo: LocalTextValue<ReactNode> = {
    "default": null,
    "United Kingdom": ClientServicesTeamUk,
    "Usa": ClientServicesTeamUsa,
};
