import * as React from "react";
import { connect } from "react-redux";

import { localTextSelector } from "./selectors";
import { KeysOfType, LocalTextValue, RegionState } from "./model";
import { LocalTextValues } from "./values";

interface ComponentVisibleStateProps {
    showChildren: boolean;
}

export type BooleanLocalisationKeys = keyof KeysOfType<LocalTextValues, LocalTextValue<boolean>>;

interface ComponentVisibleOwnProps {
    keyName: BooleanLocalisationKeys;
    children?: React.ReactNode;
}

export type ComponentVisibleProps = ComponentVisibleOwnProps & ComponentVisibleStateProps;

export const ComponentVisibleUnconnected: React.FunctionComponent<ComponentVisibleProps> = ({ children, showChildren }) => {

    if (showChildren) {
        return (
            <>
                {children}
            </>
        );
    }

    return null;
};

const mapStateToProps = (state: RegionState, { keyName }: ComponentVisibleOwnProps): ComponentVisibleStateProps => ({
    showChildren: localTextSelector(state, keyName)
});

export const ComponentVisible = connect(mapStateToProps)(ComponentVisibleUnconnected);
