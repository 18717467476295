import * as React from "react";

import { LocalTextValue } from "../model";

const ukText = (
    <p>
        Guidance on which documents are valid can be found <strong>
            <a href="https://momentagroup.com/wp-content/uploads/Background-Screening-Documents.pdf" target="_blank" rel="noopener noreferrer">here</a>
        </strong>.
    </p>
);

const ausText = (
    <p>
        Guidance on which documents are valid can be found <strong>
            <a href="https://momentagroup.com/wp-content/uploads/Background-Screening-Documents.pdf" target="_blank" rel="noopener noreferrer">here</a>
        </strong>.
    </p>
);

export const documentsToProvideText: LocalTextValue<React.ReactNode> = {
    default: ukText,
    Australia: ausText
};
