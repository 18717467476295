import * as React from "react";
import { Segment } from "semantic-ui-react";

export interface LoadingWrapperProps {
    loading: boolean;
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ loading, children }) => (
    <Segment basic loading={loading} className="loading-wrapper">
        {children}
    </Segment>
);

export default LoadingWrapper;
