/* eslint-disable max-lines */
import moment from "moment";
import { CandidateState as CandidateStatus } from "@momenta/common";
import { Company } from "@momenta/common/companies";
import { UsStateCode } from "@momenta/common/address/lookup/UsState";

import { Resourcer } from "../resourcers/model";
import { Role } from "../role";
import { Intake } from "../intake";
import { Associate } from "../associate";
import { EntityType } from "../vetting/VettingItemApi";
import { ApprovalGroup } from "../approvalGroup";
import { Vetter } from "../vetters";
export { Resourcer } from "../resourcers/model";
export { Vetter } from "../vetters/model";

export interface BulkChangeModel {
    selectAll?: boolean;
    candidates?: number[];
    associates?: number[];
    entityId?: number;
    entityType?: EntityType;
}

export interface CandidateWithdrawModel {
    readonly withdrawReason: number;
    readonly changingRole?: boolean;
    readonly newRole?: NewCandidateModel;
    readonly emailToBeSent: boolean;
}

export interface NewCandidateModel {
    associateId: number;
    roleId: number;
    intakeId?: number;
    resourcerId?: number;
    internalSource?: number;
    referral?: string;
}

export interface Candidate {
    readonly associate?: Associate;
    readonly id: number;
    readonly roleId: number;
    readonly role?: Role;
    readonly associateId: number;
    readonly resourcerId?: number;
    readonly intakeId?: number;
    readonly intake?: Intake;
    readonly resourcer?: Resourcer;
    readonly state: CandidateStatus;
    readonly availableForReview: boolean;
    readonly withdrawn: boolean;
    readonly contractDetailsConfirmed: boolean;
    readonly vetterId?: number;
    readonly vetter?: Vetter;
    readonly updated: moment.Moment;
    readonly withdrawReason: number;
    readonly withdrawReasonDescription: string;
    readonly stateDescription: string;
    readonly dayRate?: number;
    readonly dayRateStartDate?: moment.Moment;
    readonly chargeRate?: number;
    readonly chargeRateStartDate?: moment.Moment;
    readonly isMomentaAssessmentComplete: AssessmentCompletionState;
    readonly isClientAssessmentComplete: AssessmentCompletionState;
    readonly clientAssessmentRequired: boolean;
    readonly vettingStarted: boolean;
    readonly approvalGroupId?: number;
    readonly approvalGroup: ApprovalGroup;
    readonly contractSigned?: boolean;
    readonly vettingProgress?: number;
    readonly contractStart?: moment.Moment;
    readonly contractEnd?: moment.Moment;
    readonly companyId?: number;
    readonly company?: Company;
    readonly contractEndReason: number;
    readonly contractEndComment: string;
    readonly resourcingFinishedDate?: moment.Moment;
    readonly loaded?: moment.Moment;
    readonly clientReviewState: ClientReviewState;
    readonly externalApplication: boolean;
    readonly externalApplicationName: string;
    readonly contractSignedUntil?: moment.Moment;
    readonly associateNoticePeriod: string;
    readonly momentaNoticePeriod: string;
    readonly enhancedTermsAllowed: boolean;
    readonly transferredContract: boolean;
    readonly previousProjectId?: number;
    readonly previousProject: string;
    readonly previousRoleId?: number;
    readonly previousRole?: string;
    readonly assignmentConfirmed: boolean;
    readonly confirmationOfAssignmentEmailSent: boolean;
    readonly performanceClause?: string;
    readonly disbursementArrangement?: string;
    readonly healthRisk: string;
    readonly documentTemplateId?: number;
    readonly created?: moment.Moment;
    readonly stateWorking?: UsStateCode;
    readonly hasRetainer?: boolean;
    readonly retainerAmount?: number;
    readonly retainerStartDate?: moment.Moment;
    readonly retainerEndDate?: moment.Moment;
}

export enum WithdrawReason {
    Unknown = 0,
    FailedMomentaCvScreen = 10,
    FailedMomentaTeleScreen = 20,
    FailedClientCvSubmission = 30,
    FailedMomentaAssessment = 40,
    FailedMomentaInterview = 50,
    FailedClientAssessment = 60,
    FailedClientInterview = 70,
    FailedAdverseVettingFile = 80,
    IncompleteVetting = 90,
    WithdrawnPersonal = 100,
    WithdrawnOtherRole = 110,
    WithdrawnNonResponsive = 120,
    WithdrawnContractConcerns = 130,
    WithdrawnRateRoleLocationNotSuitable = 140,
    WithdrawnRequirementChange = 150,
    WithdrawnDuplicateProfile = 160,
    WithdrawnInternalChange = 170,
    WithdrawnChangedToDifferentRole = 180,

    // Any options only available to the associate should go here
    WithdrawnPersonalReasons = 500,
    WithdrawnRoleNoteForMe = 510,
    WithdrawnRateTooLow = 520,
    WithdrawnLocationNoSuitable = 530,
    WithdrawnSecuredNewRole = 540,
    WithdrawnRemainingAtCurrentRole = 550,
    WithdrawnApplyingForDifferentRole = 560,
    WithdrawnCannotCompleteBackgroundComplianceChecks = 570
}

export enum AssessmentCompletionState {
    "Unknown" = 0,
    "Complete" = 10,
    "Incomplete" = 20
}

export enum InternalSourceEnum {
    "Unknown" = 0,
    "MP2 Search" = 10,
    "LinkedId (CV database)" = 20,
    "Reed (CV database)" = 30,
    "Totaljobs (CV database)" = 40,
    "TotallyLegal (CV database)" = 50,
    "CV-Library (CV database)" = 60,
    "CareerBuilder (CV database)" = 70,
    "Recruit.hk (CV database)" = 80,
    "JobsDB (CV database)" = 90,
    "Seek Talent (CV database)" = 100,
    "Referral" = 110
}

export const ContractEndReasonOptions = {
    0: "Unknown",
    10: "Contract Expired",
    20: "Resignation",
    30: "Resignation - Breach of contract",
    40: "Given Notice By Client - Project Downsizing",
    50: "Given Notice By Client - Performance",
    60: "Given Notice By Client - Misconduct",
    70: "Given Notice By Client - Failure To Supply",
    80: "Visa Expiry",
    90: "Extension Not Accepted",
    110: "Transferring To Another Role",
    120: "Change of Contracting Entity",
    130: "Taken on a permanent basis by client",
    140: "Mutual Agreement – Contractor",
    150: "Internal Change",
    160: "Transferring To New Project",
    170: "Transferring To New Role",
    180: "Statutory Leave",
    190: "Other"
};

export enum ContractEndReason {
    "Unknown" = 0,
    "Contract Expired" = 10,
    "Resignation" = 20,
    "Resignation Breach Of Contract" = 30,
    "Given Notice By Client Project Downsizing" = 40,
    "Given Notice By Client Performance" = 50,
    "Given Notice By Client Misconduct" = 60,
    "Given Notice By Client Failure To Supply" = 70,
    "Visa Expiry" = 80,
    "Extension Not Accepted" = 90,
    "Transferring To Another Role" = 110,
    "Change of Contracting Entity" = 120,
    "Taken on a permanent basis by client" = 130,
    "Mutual Agreement – Contractor" = 140,
    "Internal Change" = 150,
    "Transferring To New Project" = 160,
    "Transferring To New Role" = 170,
    "Statutory Leave" = 180,
    "Other" = 190
}

export enum ClientReviewState {
    "No Status" = 0,
    "Ready To Start" = 5,
    "Team Lead Review" = 10,
    "Client Manager Review" = 20,
    "Client Review" = 30,
    "Client Interview" = 40,
    "Under Offer" = 50,
    "Accepted" = 60
}

export type CandidateState = {
    candidates: Candidate[];
};

export class CandidateBuilder {

    private id: number;
    private roleId: number;
    private role?: Role;
    private associateId: number;
    private resourcerId?: number;
    private intakeId?: number;
    private intake?: Intake;
    private resourcer?: Resourcer;
    private state: CandidateStatus;
    private availableForReview: boolean;
    private withdrawn: boolean;
    private contractDetailsConfirmed: boolean;
    private associate?: Associate;
    private vetterId?: number;
    private vetter: Vetter;
    private updated: moment.Moment;
    private withdrawReason: number;
    private withdrawReasonDescription: string;
    private stateDescription: string;
    private dayRate?: number;
    private dayRateStartDate?: moment.Moment;
    private chargeRate?: number;
    private chargeRateStartDate?: moment.Moment;
    private isClientAssessmentComplete: AssessmentCompletionState;
    private isMomentaAssessmentComplete: AssessmentCompletionState;
    private clientAssessmentRequired: boolean;
    private vettingStarted: boolean;
    private contractSigned: boolean;
    private vettingProgress: number;
    private contractStart: moment.Moment;
    private contractEnd: moment.Moment;
    private contractEndReason: number;
    private contractEndComment: string;
    private company: Company;
    private clientReviewState: number;
    private externalApplication: boolean;
    private externalApplicationName: string;
    private contractSignedUntil: moment.Moment;
    private associateNoticePeriod: string;
    private momentaNoticePeriod: string;
    private enhancedTermsAllowed: boolean;
    private transferredContract: boolean;
    private previousProjectId?: number;
    private previousProject: string;
    private previousRoleId?: number;
    private previousRole: string;
    private approvalGroupId: number;
    private approvalGroup: ApprovalGroup;
    private assignmentConfirmed: boolean;
    private confirmationOfAssignmentEmailSent: boolean;
    private disbursementArrangement: string;
    private healthRisk: string;
    private created: moment.Moment;
    private errorOccurred: boolean;
    private errorMessage: string;
    private documentTemplateId: number;
    private hasRetainer: boolean;
    private retainerAmount?: number;
    private retainerStartDate?: moment.Moment;
    private retainerEndDate?: moment.Moment;

    constructor() {
        this.id = 0;
        this.roleId = 0;
        this.role = undefined;
        this.associateId = 0;
        this.resourcerId = 0;
        this.intakeId = 0;
        this.intake = undefined;
        this.resourcer = undefined;
        this.state = 0;
        this.availableForReview = false;
        this.withdrawn = false;
        this.contractDetailsConfirmed = false;
        this.associate = undefined;
        this.vetterId = 0;
        this.vetter = undefined;
        this.updated = undefined;
        this.withdrawReason = undefined;
        this.withdrawReasonDescription = undefined;
        this.stateDescription = undefined;
        this.dayRate = undefined;
        this.dayRateStartDate = undefined;
        this.chargeRate = undefined;
        this.chargeRateStartDate = undefined;
        this.isClientAssessmentComplete = AssessmentCompletionState.Unknown;
        this.isMomentaAssessmentComplete = AssessmentCompletionState.Unknown;
        this.clientAssessmentRequired = false;
        this.vettingStarted = false;
        this.contractSigned = false;
        this.vettingProgress = 0;
        this.contractStart = undefined;
        this.contractEnd = undefined;
        this.contractEndReason = 0;
        this.contractEndComment = "";
        this.company = undefined;
        this.clientReviewState = 0;
        this.externalApplication = false;
        this.externalApplicationName = "";
        this.contractSignedUntil = undefined;
        this.associateNoticePeriod = "";
        this.momentaNoticePeriod = "";
        this.enhancedTermsAllowed = false;
        this.transferredContract = false;
        this.previousProjectId = undefined;
        this.previousProject = "";
        this.previousRoleId = undefined;
        this.previousRole = "";
        this.approvalGroupId = 0;
        this.approvalGroup = undefined;
        this.assignmentConfirmed = false;
        this.confirmationOfAssignmentEmailSent = false;
        this.disbursementArrangement = "Not Applicable";
        this.healthRisk = "Not Stipulated";
        this.errorOccurred = undefined;
        this.errorMessage = "";
        this.documentTemplateId = undefined;
        this.created = undefined;
        this.hasRetainer = false;
        this.retainerAmount = undefined;
        this.retainerStartDate = undefined;
        this.retainerEndDate = undefined;
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withRoleId(roleId: number) {
        this.roleId = roleId;
        return this;
    }

    public withRole(role: Role) {
        this.role = role;
        return this;
    }

    public withAssociateId(associateId: number) {
        this.associateId = associateId;
        return this;
    }

    public withResourcerId(resourcerId: number) {
        this.resourcerId = resourcerId;
        return this;
    }

    public withIntakeId(intakeId: number) {
        this.intakeId = intakeId;
        return this;
    }

    public withIntake(intake: Intake) {
        this.intake = intake;
        return this;
    }

    public withResourcer(resourcer: Resourcer) {
        this.resourcer = resourcer;
        return this;
    }

    public withState(state: CandidateStatus) {
        this.state = state;
        return this;
    }

    public withAvailableForReview(availableForReview: boolean) {
        this.availableForReview = availableForReview;
        return this;
    }

    public withWithdrawn(withdrawn: boolean) {
        this.withdrawn = withdrawn;
        return this;
    }

    public withContractDetailsConfirmed(contractDetailsConfirmed: boolean) {
        this.contractDetailsConfirmed = contractDetailsConfirmed;
        return this;
    }

    public withAssociate(associate: Associate) {
        this.associate = associate;
        return this;
    }

    public withUpdated(updated: moment.Moment) {
        this.updated = updated;
        return this;
    }

    public withWithdrawReason(withdrawnReason: number) {
        this.withdrawReason = withdrawnReason;
        return this;
    }

    public withWithdrawReasonDescription(description: string) {
        this.withdrawReasonDescription = description;
        return this;
    }

    public withStateDescription(description: string) {
        this.stateDescription = description;
        return this;
    }

    public withDayRate(dayRate?: number) {
        this.dayRate = dayRate;
        return this;
    }

    public withDayRateStartDate(dayRateStartDate?: moment.Moment) {
        this.dayRateStartDate = dayRateStartDate;
        return this;
    }

    public withIsClientAssessmentComplete(isClientAssessmentComplete: AssessmentCompletionState) {
        this.isClientAssessmentComplete = isClientAssessmentComplete;
        return this;
    }

    public withIsMomentaAssessmentComplete(isMomentaAssessmentComplete: AssessmentCompletionState) {
        this.isMomentaAssessmentComplete = isMomentaAssessmentComplete;
        return this;
    }

    public withChargeRate(chargeRate?: number) {
        this.chargeRate = chargeRate;
        return this;
    }

    public withChargeRateStartDate(chargeRateStartDate?: moment.Moment) {
        this.chargeRateStartDate = chargeRateStartDate;
        return this;
    }

    public withClientAssessmentRequired(clientAssessmentRequired: boolean) {
        this.clientAssessmentRequired = clientAssessmentRequired;
        return this;
    }

    public withVettingStarted(vettingStarted: boolean) {
        this.vettingStarted = vettingStarted;
        return this;
    }

    public withContractSigned(contractSigned: boolean) {
        this.contractSigned = contractSigned;
        return this;
    }

    public withVettingProgress(vettingProgress: number) {
        this.vettingProgress = vettingProgress;
        return this;
    }

    public withStart(start: moment.Moment) {
        this.contractStart = start;
        return this;
    }

    public withEnd(end: moment.Moment) {
        this.contractEnd = end;
        return this;
    }

    public withContractEndReason(contractEndReason: number) {
        this.contractEndReason = contractEndReason;
        return this;
    }

    public withContractEndComment(contractEndComment: string) {
        this.contractEndComment = contractEndComment;
        return this;
    }

    public withContractStart(contractStart: moment.Moment) {
        this.contractStart = contractStart;
        return this;
    }

    public withContractEnd(contractEnd: moment.Moment) {
        this.contractEnd = contractEnd;
        return this;
    }

    public withCompany(company: Company) {
        this.company = company;
        return this;
    }

    public withClientReviewState(clientReviewState: number) {
        this.clientReviewState = clientReviewState;
        return this;
    }

    public withExternalApplication(externalApplication: boolean) {
        this.externalApplication = externalApplication;
        return this;
    }

    public withExternalApplicationName(externalApplicationName: string) {
        this.externalApplicationName = externalApplicationName;
        return this;
    }

    public withContractSignedUntil(contractSignedUntil: moment.Moment) {
        this.contractSignedUntil = contractSignedUntil;
        return this;
    }

    public withAssociateNoticePeriod(associateNoticePeriod: string) {
        this.associateNoticePeriod = associateNoticePeriod;
        return this;
    }

    public withMomentaNoticePeriod(momentaNoticePeriod: string) {
        this.momentaNoticePeriod = momentaNoticePeriod;
        return this;
    }

    public withEnhancedTermsAllowed(enhancedTermsAllowed: boolean) {
        this.enhancedTermsAllowed = enhancedTermsAllowed;
        return this;
    }

    public withTransferredContract(transferredContract: boolean) {
        this.transferredContract = transferredContract;
        return this;
    }

    public withPreviousRoleId(previousRoleId: number) {
        this.previousRoleId = previousRoleId;
        return this;
    }

    public withPreviousRole(previousRole: string) {
        this.previousRole = previousRole;
        return this;
    }

    public withPreviousProjectId(previousProjectId: number) {
        this.previousProjectId = previousProjectId;
        return this;
    }

    public withPreviousProject(previousProject: string) {
        this.previousProject = previousProject;
        return this;
    }

    public withApprovalGroupId(approvalGroupId: number) {
        this.approvalGroupId = approvalGroupId;
        return this;
    }

    public withApprovalGroup(approvalGroup: ApprovalGroup) {
        this.approvalGroup = approvalGroup;
        return this;
    }

    public withAssignmentConfirmed(assignmentConfirmed: boolean) {
        this.assignmentConfirmed = assignmentConfirmed;
        return this;
    }

    public withConfirmationOfAssignmentEmailSent(confirmationOfAssignmentEmailSent: boolean) {
        this.confirmationOfAssignmentEmailSent = confirmationOfAssignmentEmailSent;
        return this;
    }

    public withDisbursementArrangement(disbursementArrangement: string) {
        this.disbursementArrangement = disbursementArrangement;
        return this;
    }

    public withHealthRisk(healthRisk: string) {
        this.healthRisk = healthRisk;
        return this;
    }

    public withDocumentTemplateId(documentTemplateId: number) {
        this.documentTemplateId = documentTemplateId;
        return this;
    }

    public withCreated(created: moment.Moment) {
        this.created = created;
        return this;
    }

    public withHasRetainer(hasRetainer: boolean) {
        this.hasRetainer = hasRetainer;
        return this;
    }

    public withRetainerAmount(amount: number) {
        this.retainerAmount = amount;
        return this;
    }

    public withRetainerStartDate(retainerStartDate: moment.Moment) {
        this.retainerStartDate = retainerStartDate;
        return this;
    }

    public withRetainerEndDate(retainerEndDate: moment.Moment) {
        this.retainerEndDate = retainerEndDate;
        return this;
    }

    public build(): Candidate {
        return {
            id: this.id,
            roleId: this.roleId,
            role: this.role,
            associateId: this.associateId,
            resourcerId: this.resourcerId,
            intakeId: this.intakeId,
            intake: this.intake,
            resourcer: this.resourcer,
            state: this.state,
            availableForReview: this.availableForReview,
            withdrawn: this.withdrawn,
            contractDetailsConfirmed: this.contractDetailsConfirmed,
            associate: this.associate,
            vetterId: this.vetterId,
            vetter: this.vetter,
            updated: this.updated,
            withdrawReason: this.withdrawReason,
            withdrawReasonDescription: this.withdrawReasonDescription,
            stateDescription: this.stateDescription,
            dayRate: this.dayRate,
            dayRateStartDate: this.dayRateStartDate,
            chargeRate: this.chargeRate,
            chargeRateStartDate: this.chargeRateStartDate,
            isClientAssessmentComplete: this.isClientAssessmentComplete,
            isMomentaAssessmentComplete: this.isMomentaAssessmentComplete,
            clientAssessmentRequired: this.clientAssessmentRequired,
            vettingStarted: this.vettingStarted,
            contractSigned: this.contractSigned,
            vettingProgress: this.vettingProgress,
            contractStart: this.contractStart,
            contractEnd: this.contractEnd,
            contractEndReason: this.contractEndReason,
            contractEndComment: this.contractEndComment,
            company: this.company,
            clientReviewState: this.clientReviewState,
            externalApplication: this.externalApplication,
            externalApplicationName: this.externalApplicationName,
            contractSignedUntil: this.contractSignedUntil,
            associateNoticePeriod: this.associateNoticePeriod,
            momentaNoticePeriod: this.momentaNoticePeriod,
            enhancedTermsAllowed: this.enhancedTermsAllowed,
            transferredContract: this.transferredContract,
            previousProjectId: this.previousProjectId,
            previousProject: this.previousProject,
            previousRoleId: this.previousRoleId,
            previousRole: this.previousRole,
            approvalGroupId: this.approvalGroupId,
            approvalGroup: this.approvalGroup,
            assignmentConfirmed: this.assignmentConfirmed,
            confirmationOfAssignmentEmailSent: this.confirmationOfAssignmentEmailSent,
            disbursementArrangement: this.disbursementArrangement,
            healthRisk: this.healthRisk,
            created: this.created,
            hasRetainer: this.hasRetainer,
            retainerAmount: this.retainerAmount,
            retainerStartDate: this.retainerStartDate,
            retainerEndDate: this.retainerEndDate,
        };
    }
}
