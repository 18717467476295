export function getExtension(file: string) {

    if (file == null || file === undefined) {
        return "";
    }

    const dotIndex = file.lastIndexOf(".");
    const hookIndex = file.lastIndexOf("?");

    if (dotIndex === -1) {
        return "";
    }

    if (hookIndex !== -1) {
        return file.substr(dotIndex + 1, hookIndex - dotIndex - 1);
    } else {
        return file.substr(dotIndex + 1);
    }
}

export function isImage(file: string) {
    const extension = getExtension(file);
    return /(png|jpg|jpeg|gif|svg|bmp|ico|apng)$/ig.test(extension);
}
