import { ReactNode } from "react";
import { FinanceTeamAus, FinanceTeamUk, FinanceTeamUsa } from "src/App/Associate/src/momentaTeams/FinanceTeam";

import { LocalTextValue } from "../../model";

export const financeInfo: LocalTextValue<ReactNode> = {
    "default": FinanceTeamUk,
    "United Kingdom": FinanceTeamUk,
    "Australia": FinanceTeamAus,
    "Usa": FinanceTeamUsa,
};
