import * as React from "react";

export const VettingTeamUk = (<>
    <p>Vetting Team</p>
    <p>Tel: 0207 374 6600 (Option 2)</p>
    <p>Email: <a className="momenta-team-link" href="mailto:vetting@momentagroup.com">vetting@momentagroup.com</a></p>
    <p>
        Once your application is in process, our Vetting & Compliance team will work with you to ensure that all background
        and referencing checks are completed to the required industry standards before you start your new role. This process
        can be complex but the team will make sure it runs as smoothly as possible for you.
    </p>
</>);

export const VettingTeamAus = (<>
    <p>Vetting Team</p>
    <p>Tel: 028871 8803</p>
    <p>Email: <a className="momenta-team-link" href="mailto:screening@momentaresourcing.com.au">screening@momentaresourcing.com.au</a></p>
    <p>
        Once your application is in process, our Vetting & Compliance team will work with you to ensure that all background and referencing
        checks are completed to the required industry standards before you start your new role. This process can be complex but the team will
        make sure it runs as smoothly as possible for you.
    </p>
</>);

export const VettingTeamUsa = (<>
    <p>Vetting Team</p>
    <p>Tel: +1 917 781 4652 (Option 2)</p>
    <p>Email: <a className="momenta-team-link" href="mailto:usassociates@momentagroup.com">usassociates@momentagroup.com</a></p>
    <p>
        Once your application is in process, our Vetting & Compliance team will work with you to ensure that all background and referencing
        checks are completed to the required industry standards before you start your new role. This process can be complex but the team will
        make sure it runs as smoothly as possible for you.
    </p>
</>);
