import { numericValidator } from "@momenta/common/validators/numericValidator";
import { validators, ValidationResult } from "not-valid";

import { LocalTextValue } from "../../model";

export const bankCodeValidator: LocalTextValue<((value: string) => ValidationResult)[]> = {
    default: [],
    India: [validators.validLength({ min: 11, max: 11 })],
    Australia: [numericValidator(), validators.validLength({ min: 6, max: 6 })]
};
