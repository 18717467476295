import { AxiosStatic } from "axios";
import { noop } from "lodash";

/* eslint-disable @typescript-eslint/consistent-type-assertions */
export const axiosMock = {
    get: noop,
    put: noop,
    post: noop,
    delete: noop
} as AxiosStatic;
