import { numericValidator } from "@momenta/common/validators/numericValidator";
import { validators, ValidationResult } from "not-valid";

import { LocalTextValue } from "../../model";

export const accountNumberValidator: LocalTextValue<((value: string) => ValidationResult)[]> = {
    default: [],
    India: [numericValidator(), validators.validLength({ min: 9, max: 18 })],
    Australia: [numericValidator(), validators.validLength({ min: 6, max: 10 })]
};
