import * as React from "react";
import { Image, ImageProps } from "semantic-ui-react";

import { buildInfo } from "./buildInfo";

interface CdnImageProps {
    src: string;
}

export const CdnImage: React.FunctionComponent<CdnImageProps & ImageProps> = ({ src, ...props }) => (
    <Image src={`${buildInfo.cdnUrl}images${src}`} {...props} />
);

export const CdnImg: React.FunctionComponent<CdnImageProps> = ({ src }) => (
    <img src={`${buildInfo.cdnUrl}images${src}`} />
);
