import moment from "moment";

import { isNullOrUndefined } from "..";

export function dateSortAsc<T>(...getProperties: ((obj: T) => moment.Moment)[]) {
    return (a: T, b: T) => {

        for (const getProperty of getProperties) {

            const aProp = getProperty(a);
            const bProp = getProperty(b);

            if (isNullOrUndefined(aProp) && isNullOrUndefined(bProp)) {
                return 0;
            }

            if (isNullOrUndefined(aProp) && bProp) {
                return 1;
            }

            if (isNullOrUndefined(bProp) && aProp) {
                return -1;
            }

            if (aProp.isAfter(bProp)) {
                return 1;
            } else if (aProp.isBefore(bProp)) {
                return -1;
            }
        }

        return 0;
    };
}
