export function isNullOrUndefined(value: any) {
    return value == null || value === undefined;
}

export function isNotNullOrUndefined(value: any) {
    return !isNullOrUndefined(value);
}

export function isNotNullUndefinedOrEmpty(value: string) {
    return !isNullOrUndefined(value) && value !== "";
}
