import * as React from "react";
import { Grid, Container } from "semantic-ui-react";

import { LocalTextValue } from "../model";

const UkFooter = (
    <footer className="landing-footer">
        <Container>
            <Grid textAlign="center" verticalAlign="bottom">
                <Grid.Column width={5}>
                    <a href="https://www.momentagroup.com/privacy-and-cookie-policy/" target="_blank" rel="noopener noreferrer">
                        Privacy and Cookie Policy
                    </a>
                </Grid.Column>
                <Grid.Column width={6}>
                    <a href="https://www.momentagroup.com/contractor-privacy-policy/" target="_blank" rel="noopener noreferrer">
                        Privacy Policy for Contractors and Potential Contractors
                    </a>
                </Grid.Column>
                <Grid.Column width={5}>
                    <a href="https://momentagroup.com/website-terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                        Terms and Conditions
                    </a>
                </Grid.Column>
            </Grid>
        </Container>
    </footer>
);

const OtherRegionsFooter = (
    <footer className="landing-footer">
        <Container>
            <Grid textAlign="center" verticalAlign="bottom">
                <Grid.Column width={8}>
                    <a href="https://momentagroup.com/website-terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                        T&Cs
                    </a>
                </Grid.Column>
                <Grid.Column width={8}>
                    <a href="https://momentagroup.com/privacy-and-cookie-policy/" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>
                </Grid.Column>
            </Grid>
        </Container>
    </footer>
);

export const associateFooter: LocalTextValue<React.ReactNode> = {
    default: UkFooter,
    Australia: OtherRegionsFooter,
    India: OtherRegionsFooter
};
