import { encodeFilterValue } from "./encodeFilterValue";
import { getKey } from "./getKey";
import { FilterValue } from "./FilterValue";

export type ODataOperator = "eq" | "startsWith" | "ge" | "lt" | "gt" | "ne" | "le" | "contains";
type ODataJoiner = "or" | "and";

export type FilterValueType<T> = T extends (string | number | boolean) ? T | FilterValue<T> : FilterValue<T>;

export class FilterBuilder<T> {

    private filter: string = "";

    public and<K extends keyof T, K1 extends keyof T[K], K2 extends keyof T[K][K1]>(
        prop: K,
        prop1: K1,
        prop2: K2,
        operator: ODataOperator,
        value: FilterValueType<T[K][K1][K2]>
    ): FilterBuilder<T>;
    public and<K extends keyof T, K1 extends keyof T[K]>(
        prop: K,
        prop1: K1,
        operator: ODataOperator,
        value: FilterValueType<T[K][K1]>
    ): FilterBuilder<T>;
    public and<K extends keyof T>(prop: K, operator: ODataOperator, value: FilterValueType<T[K]>): FilterBuilder<T>;
    public and(filter: string): FilterBuilder<T>;
    public and(...args: any[]): FilterBuilder<T> {

        this.addFilter("and", ...args);
        return this;
    }

    public or<K extends keyof T, K1 extends keyof T[K], K2 extends keyof T[K][K1]>(
        prop: K,
        prop1: K1,
        prop2: K2,
        operator: ODataOperator,
        value: FilterValueType<T[K][K1][K2]>
    ): FilterBuilder<T>;
    public or<K extends keyof T, K1 extends keyof T[K]>(
        prop: K,
        prop1: K1,
        operator: ODataOperator,
        value: FilterValueType<T[K][K1]>
    ): FilterBuilder<T>;
    public or<K extends keyof T>(prop: K, operator: ODataOperator, value: FilterValueType<T[K]>): FilterBuilder<T>;
    public or(filter: string): FilterBuilder<T>;
    public or(...args: any[]): FilterBuilder<T> {

        this.addFilter("or", ...args);
        return this;
    }

    public build(): string {
        return this.filter === ""
            ? undefined
            : this.filter;
    }

    private addFilter(joiner: ODataJoiner, ...args: any[]) {
        let filter: string;

        if (args.length === 1) {
            filter = args[0] && args[0].length ? `(${args[0]})` : "";
        } else {

            const value = args[args.length - 1];
            const operator = args[args.length - 2];
            const props = args.slice(0, args.length - 2);
            const key = getKey(...props);

            filter = this.applyOperator(key, operator, value);
        }

        this.join(joiner, filter);
    }

    private join(joiner: ODataJoiner, filter: string) {

        if (!filter.length) {
            return;
        }

        this.filter = this.filter.length
            ? `${this.filter} ${joiner} ${filter}`
            : filter;
    }

    private applyOperator<TValue>(key: string, operator: ODataOperator, value: FilterValueType<TValue>) {

        const encodedValue = encodeFilterValue(value);

        if (encodedValue === "") {
            return "";
        }

        switch (operator) {
            case "startsWith":
                return `startswith(${key}, ${encodedValue})`;
            case "contains":
                return `contains(${key}, ${encodedValue})`;
            default:
                return `${key} ${operator} ${encodedValue}`;
        }
    }
}
