/* eslint-disable max-classes-per-file */
import moment from "moment";
import { State as RouterState } from "redux-little-router";
import { DropdownItemProps } from "semantic-ui-react";

import { TimeTypeConfiguration } from "../hierarchicalConfiguration";
import { Expense } from "../expenses";
import { Bonus } from "../bonuses";
import { InvoicePeriod } from "../invoicePeriods";

export interface Timesheet {
    id: number;
    start?: moment.Moment;
    end?: moment.Moment;
    invoicePeriodId: number;
    invoicePeriod?: InvoicePeriod;
    timesheetDays: TimesheetDay[];
    state: TimesheetStateEnum;
    expenses: Expense[];
    bonuses: Bonus[];
    updated: moment.Moment;
    originalTimesheetId?: number;
    originalTimesheet?: Timesheet;
    isAdjusted?: boolean;
    adjustmentReason?: string;
    dateSubmitted?: moment.Moment;
    dateApproved?: moment.Moment;
    dateRejected?: moment.Moment;
    rejectionReason?: string;
    approverId?: number;
    approver?: string;
    synced?: boolean;
    timesheetEntryDenied?: boolean;
    timesheetSaveSuccessful?: boolean;
}

export interface Approver {
    id: number;
    clientUserId?: number;
    clientUser?: ClientUser;
}

export interface ClientUser {
    id: number;
    forename: string;
    surname: string;
    fullName: string;
}

export interface TimesheetDay {
    id: number;
    date: moment.Moment;
    timesheetEntries: TimesheetEntry[];
}

export interface TimesheetEntry {
    id?: number;
    amount: number;
    timeTypeId?: number;
    timeType: TimeTypeConfiguration;
    hoursMinutes: string;
}

export const timesheetEntryOptions: DropdownItemProps[] = [
    {
        text: "Half Day",
        value: 0.5
    },
    {
        text: "Full Day",
        value: 1
    }
];

export const negativeTimesheetEntryOptions: DropdownItemProps[] = [
    ...timesheetEntryOptions,
    {
        text: "Negative Half Day",
        value: -0.5
    },
    {
        text: "Negative Full Day",
        value: -1
    }
];

export type TimesheetState = {
    timesheets: Timesheet[]
};

export enum TimesheetStateEnum {
    Unknown = 0,
    Incomplete = 10,
    Rejected = 15,
    Submitted = 20,
    Approved = 30
}

export const TimesheetStateOption = {
    0: "Unknown",
    10: "Incomplete",
    15: "Rejected",
    20: "Submitted",
    30: "Approved"
};

export type AppState = TimesheetState & RouterState;

export class TimesheetBuilder {
    private id: number;
    private start?: moment.Moment;
    private invoicePeriodId: number;
    private timesheetDays: TimesheetDay[];
    private invoicePeriod: InvoicePeriod;
    private state: TimesheetStateEnum;
    private expenses: Expense[];
    private updated: moment.Moment;
    private bonuses: Bonus[];
    private isAdjusted?: boolean;
    private approver: string;
    private rejectionReason: string;
    private adjustmentReason: string;

    constructor() {
        this.id = 0;
        this.start = undefined;
        this.invoicePeriodId = 0;
        this.timesheetDays = undefined;
        this.invoicePeriod = undefined;
        this.state = 0;
        this.expenses = undefined;
        this.bonuses = undefined;
        this.updated = undefined;
        this.isAdjusted = false;
        this.approver = "";
        this.rejectionReason = "";
        this.adjustmentReason = "";
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withStart(start: moment.Moment) {
        this.start = start;
        return this;
    }

    public withInvoicePeriodId(invoicePeriodId: number) {
        this.invoicePeriodId = invoicePeriodId;
        return this;
    }

    public withTimesheetDays(days: TimesheetDay[]) {
        this.timesheetDays = days;
        return this;
    }

    public withInvoicePeriod(invoicePeriod: InvoicePeriod) {
        this.invoicePeriod = invoicePeriod;
        return this;
    }

    public withState(state: TimesheetStateEnum) {
        this.state = state;
        return this;
    }

    public withExpenses(expenses: Expense[]) {
        this.expenses = expenses;
        return this;
    }

    public withUpdated(updated: moment.Moment) {
        this.updated = updated;
        return this;
    }

    public withBonuses(bonuses: Bonus[]) {
        this.bonuses = bonuses;
        return this;
    }

    public withAdjusted(isAdjustment: boolean) {
        this.isAdjusted = isAdjustment;
        return this;
    }

    public withApprover(approver: string) {
        this.approver = approver;
        return this;
    }

    public withRejectionReason(rejectionReason: string) {
        this.rejectionReason = rejectionReason;
        return this;
    }

    public withAdjustmentReason(adjustmentReason: string) {
        this.adjustmentReason = adjustmentReason;
        return this;
    }

    public build(): Timesheet {
        return {
            id: this.id,
            start: this.start,
            invoicePeriodId: this.invoicePeriodId,
            timesheetDays: this.timesheetDays,
            invoicePeriod: this.invoicePeriod,
            state: this.state,
            expenses: this.expenses,
            updated: this.updated,
            bonuses: this.bonuses,
            isAdjusted: this.isAdjusted,
            approver: this.approver,
            rejectionReason: this.rejectionReason,
            adjustmentReason: this.adjustmentReason
        };
    }
}

export class TimesheetDayBuilder {
    private id: number;
    private date: moment.Moment;
    private timesheetEntries: TimesheetEntry[];

    constructor() {
        this.id = 0;
        this.date = undefined;
        this.timesheetEntries = undefined;
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withDate(date: moment.Moment) {
        this.date = date;
        return this;
    }

    public withTimesheetEntries(timesheetEntries: TimesheetEntry[]) {
        this.timesheetEntries = timesheetEntries;
        return this;
    }

    public build() {
        return {
            id: this.id,
            date: this.date,
            timesheetEntries: this.timesheetEntries
        };
    }
}

export class TimesheetEntryBuilder {
    private id?: number;
    private amount: number;
    private timeTypeId?: number;
    private timeType: TimeTypeConfiguration;
    private hoursMinutes: string;

    constructor() {
        this.id = 0;
        this.amount = 0;
        this.timeTypeId = null;
        this.hoursMinutes = null;
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }
    public withAmount(amount: number) {
        this.amount = amount;
        return this;
    }
    public withTimeTypeId(id: number) {
        this.timeTypeId = id;
        return this;
    }

    public withTimeType(timeType: TimeTypeConfiguration) {
        this.timeType = timeType;
        return this;
    }

    public withHoursMinutes(hoursMinutes: string) {
        this.hoursMinutes = hoursMinutes;
        return this;
    }

    public build(): TimesheetEntry {
        return {
            id: this.id,
            amount: this.amount,
            timeTypeId: this.timeTypeId,
            timeType: this.timeType,
            hoursMinutes: this.hoursMinutes
        };
    }
}
