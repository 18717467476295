import * as React from "react";
import { Label, SemanticCOLORS } from "semantic-ui-react";

import { getConfig } from "./config/getConfig";

const EnvironmentLabel: React.SFC = () => {

    const environment = getConfig().EnvironmentName;
    if (environment === "Live" || environment === undefined) {
        return null;
    }

    let color: SemanticCOLORS;

    switch (environment) {
        case "Development":
            color = "orange";
            break;
        case "QAT":
            color = "purple";
            break;
        case "UAT":
            color = "pink";
            break;
        default:
            color = "grey";
    }

    return <Label color={color} icon="flask" content={environment} />;
};

export { EnvironmentLabel };
