import * as React from "react";
import { connect } from "react-redux";

import { getFormattedCurrencyNumber } from "../getFormattedCurrencyNumber";

import { RegionState } from "./model";
import { localeSelector, localTextSelector } from "./selectors";

interface CurrencyOwnProps {
    value: number;
}

interface CurrencyStateProps {
    locale: string;
    currency: string;
}

type CurrencyProps = CurrencyOwnProps & CurrencyStateProps;

export const CurrencyUnconnected: React.SFC<CurrencyProps> = ({ value, locale, currency }) => {
    const formatted = getFormattedCurrencyNumber(value, locale, currency);
    return <>{formatted}</>;
};

const mapStateToProps = (state: RegionState): CurrencyStateProps => ({
    currency: localTextSelector(state, "currency"),
    locale: localeSelector()
});

export const Currency = connect(mapStateToProps)(CurrencyUnconnected);
