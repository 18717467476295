import axios from "axios";
import { makeRequest, PageResult } from "@momenta/common";

import { GlobalConfig } from "./model";

class GlobalConfigApi {

    private readonly apiUrl = "/api/globalConfig";

    public async getAll(): Promise<PageResult<GlobalConfig>> {
        const request = makeRequest(`${this.apiUrl}`, {
            orderby: "Name"
        });
        const response = await axios.get(request);
        const { items, count } = response.data as PageResult<GlobalConfig>;
        return {
            items,
            count
        };
    }

    public async save(expenseType: GlobalConfig): Promise<GlobalConfig> {
        const response = await axios.put<GlobalConfig>(`${this.apiUrl}/${expenseType.id}`, expenseType);
        return response.data;
    }
}

export {
    GlobalConfigApi
};
