import * as React from "react";

import { BooleanLocalisationKeys, ComponentVisible } from "./ComponentVisible";

export const componentVisible = (keyName: BooleanLocalisationKeys) =>
<TProps extends {}>(Component: React.ComponentClass<TProps> | React.FunctionComponent<TProps>) => {
    return (props: TProps) => (
        <ComponentVisible keyName={keyName}>
            <Component {...props} />
        </ComponentVisible>
    );
};
