import * as React from "react";
import axios from "axios";
import { Button, Form, Label, Progress, SemanticSIZES } from "semantic-ui-react";

import { isNotNullOrUndefined } from "./typeUtils";

export interface FileUploadProps {
    apiUrl: string;
    fileTypes?: string[];
    value?: string;
    filename?: string;
    label?: string;
    required?: boolean;
    onFileChange?: (value: string, valid: boolean, fileName: string) => void;
    placeholder?: string;
    className?: string;
    showErrors?: boolean;
    disabled?: boolean;
    capture?: boolean;
    deleteOnClear?: boolean;
    hideTextInput?: boolean;
    uploadText?: string;
    noClear?: boolean;
    size?: SemanticSIZES;
    setUploading?: (uploading: boolean) => void;
}

export interface FileUploadState {
    blobName: string;
    fileName: string;
    loading: boolean;
    uploaded: boolean;
    progress: number;
    error: boolean;
    valid: boolean;
    errorMessage: string;
    dirty: boolean;
}

class FileUpload extends React.Component<FileUploadProps, FileUploadState> {

    private fileInput: any;

    constructor(props: FileUploadProps) {
        super(props);
        this.state = this.initialState(props.value, props.required, props.filename);
        this.handleFileUploadClick = this.handleFileUploadClick.bind(this);

        if (props.required && props.onFileChange) {
            const valid = isNotNullOrUndefined(props.value);
            props.onFileChange(props.value, valid, this.state.fileName);
        }
    }

    public render() {
        const { loading, progress, uploaded, error, errorMessage, dirty } = this.state;
        const { className, label, disabled, deleteOnClear, uploadText, noClear, capture, size } = this.props;
        const classes = `${className || ""} field-wrapper file-upload`;
        const loadingClass = loading ? " loading" : "";

        const fileTypes = this.props.fileTypes || ["*"];
        const placeholder = this.props.placeholder || "Upload...";

        const showErrors = error && dirty || this.props.showErrors && !this.props.value;

        let actionIcon = capture ? "camera" : "upload";
        let actionLabel = uploadText === "" ? undefined : "Upload";

        if (uploaded && !noClear) {
            actionIcon = deleteOnClear ? "trash alternate outline" : "delete";
            actionLabel = deleteOnClear ? "Delete" : "Clear";
        }

        const buttonProps = {
            icon: actionIcon,
            content: actionLabel,
            onClick: this.handleFileUploadClick,
            primary: !uploaded || noClear,
            negative: uploaded && !noClear,
            disabled,
            type: "button",
            size
        };

        const setInput = (input: any) => { this.fileInput = input; };
        const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.handleFileChange(e); };
        const handleFileInputClick = () => { this.fileInput.click(); };

        return (
            <div className={`${classes}${loadingClass}`}>
                <input
                    type="file"
                    ref={setInput}
                    className="hidden"
                    onChange={handleFileChange}
                    accept={fileTypes.join(",")}
                    capture={this.props.capture}
                />

                {!this.props.hideTextInput && <Form.Input
                    label={label}
                    action={buttonProps}
                    placeholder={placeholder}
                    readOnly
                    error={error}
                    disabled={loading || disabled}
                    loading={loading}
                    onClick={handleFileInputClick}
                    value={this.state.fileName}
                />}

                {this.props.hideTextInput && <Button {...buttonProps} />}

                {loading && <Progress
                    percent={progress}
                    size="tiny"
                    active={progress < 100 && (!uploaded || !error)}
                    success={uploaded}
                    error={error}
                />}
                {showErrors && errorMessage && (
                    <Label basic color="red" pointing>
                        {errorMessage}
                    </Label>
                )}
            </div>
        );
    }

    public componentDidUpdate(prevProps: FileUploadProps) {
        if (this.props === prevProps || this.props.value === this.state.blobName) {
            return;
        }
        this.setState(this.initialState(this.props.value, this.props.required, this.props.filename));
        this.onFileChange();
    }

    private handleFileUploadClick(event: any) {
        event.preventDefault();
        if (this.state.uploaded) {
            this.reset();
        }
        if (this.state.uploaded === false) {
            this.fileInput.click();
        }
    }

    private reset() {
        if (this.props.deleteOnClear) {
            axios.delete(`${this.props.apiUrl}/${this.state.blobName}`);
        }

        this.setState(this.initialState(undefined, this.props.required), this.onFileChange);
    }

    private async handleFileChange(event: any) {

        this.setState({
            fileName: event.target.files[0].name,
            loading: true,
            progress: 0,
            error: false
        });

        const setUploading = this.props.setUploading;

        if (setUploading) {
            setUploading(true);
        }

        const data = new FormData();
        data.append("file", event.target.files[0]);

        try {
            const response = await axios.post(this.props.apiUrl, data, {
                onUploadProgress: progress => {
                    this.setState({
                        progress: (progress.loaded / progress.total) * 90
                    });
                }
            });

            this.setState({
                blobName: response.data.blobName,
                uploaded: true,
                progress: 100
            });

        } catch (error) {
            this.setState({
                error: true,
                errorMessage: error.response && error.response.data && error.response.data.message
            });
        } finally {
            this.setState({
                loading: false
            });

            this.onFileChange();
            if (setUploading) {
                setUploading(false);
            }
        }
    }

    private onFileChange() {
        this.setState(
            { valid: !this.props.required || this.state.uploaded, dirty: true },
            () => this.props.onFileChange && this.props.onFileChange(this.state.blobName, this.state.valid, this.state.fileName));
    }

    private initialState(value: string, required: boolean, filename?: string): FileUploadState {
        return {
            blobName: value,
            fileName: filename || value || "",
            loading: false,
            uploaded: value != null,
            error: false,
            progress: 0,
            errorMessage: required ? "This field is required" : undefined,
            valid: required === false || isNotNullOrUndefined(value),
            dirty: value !== undefined
        };
    }
}

export { FileUpload };
