import { User } from "reauthorize";

import * as actions from "./actiontypes";
import { CurrentUserAction } from "./actions";

export const getReducer = <T extends User>(defaultState: T) => {
    return (state: T = defaultState, action: CurrentUserAction<T>): T => {
        switch (action.type) {
            case actions.GET_CURRENT_USER_SUCCESS:
                return action.payload;
            default:
                return state;
        }
    };
};
