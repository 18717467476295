import * as React from "react";
import { Container, Header, Icon, Message } from "semantic-ui-react";

export const Forbidden: React.FC<{}> = () => (
    <Container textAlign="center">
        <Header as="h1" color="red" textAlign="center" icon>
            <Icon name="warning sign" />
            <Header.Content>Forbidden</Header.Content>
        </Header>

        <Message negative compact>
            <Message.Header>
                You tried to access a route you do not have permission to see
            </Message.Header>
            <p>
                If you believe this is a mistake please contact your administrator
            </p>
        </Message>
    </Container>
);
