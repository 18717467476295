import * as React from "react";
import moment from "moment";
import { Button, DropdownItemProps, Form, Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { ValidationState } from "@momenta/common/validationState";
import { negativeTimeValidator, positiveTimeValidator } from "@momenta/common/validators/timeValidator";

import { TimeEntryType, TimeTypeConfiguration } from "../hierarchicalConfiguration";
import { isNullOrUndefined } from "../typeUtils";
import { greaterThanZeroValidator } from "../validators/greaterThanZeroValidator";
import { nonZeroValidator } from "../validators/nonZeroValidator";

import { DurationInputComponent } from "./DurationInputComponent";
import { TimesheetEntry } from "./model";
import { dateConfigOptionSelector } from "./dateConfigOptionSelector";

interface TimesheetEntryEditProps {
    timesheetEntry: TimesheetEntry;
    timeTypeConfigurations: TimeTypeConfiguration[];
    projectTimeType: TimeEntryType;
    onDelete: () => void;
    onChange: (timesheetEntry: TimesheetEntry, valid: ValidationState) => void;
    submitted: boolean;
    timesheetEntryValid: ValidationState;
    showErrors: boolean;
    timesheetEntryOptions: DropdownItemProps[];
    isAdjustment: boolean;
    workedHoursRequired: boolean;
    date: moment.Moment;
}

export class TimesheetEntryEdit extends React.Component<TimesheetEntryEditProps> {
    constructor(props: TimesheetEntryEditProps) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getTimeTypeOptionValue = this.getTimeTypeOptionValue.bind(this);
    }

    public render() {
        const typeOptions = dateConfigOptionSelector({
            config: this.props.timeTypeConfigurations,
            date: this.props.date
        });

        const timeEntryType = this.getTimeEntryType();
        const multiplier = this.getTimeEntryTypeMultiplier();

        const showDurationField = this.props.workedHoursRequired
            && this.props.projectTimeType === TimeEntryType.Days
            && timeEntryType === TimeEntryType.Days
            && multiplier > 0;

        return (
            <>
                <Grid.Column mobile={16} computer={4}>
                    <Input.DropdownNumber
                        placeholder="Select type"
                        options={typeOptions}
                        label="Type"
                        onChange={this.onTimeTypeChange}
                        value={this.getTimeTypeOptionValue()}
                        disabled={this.props.submitted}
                        showErrors={this.props.showErrors}
                        dynamicOptions
                        required
                    />
                </Grid.Column>
                {this.getTimeOptionField()}
                {showDurationField && this.getHoursMinutesField()}
                <Grid.Column mobile={16} computer={1}>
                    <Form.Field>
                        <label className="empty-label">&nbsp;</label>
                        <Button
                            icon="trash alternate outline"
                            onClick={this.handleClick}
                            disabled={this.props.submitted}
                            color="red"
                            floated="right"
                        />
                    </Form.Field>
                </Grid.Column>
            </>
        );
    }

    private getTimeTypeOptionValue() {
        const timesheetEntry = this.props.timesheetEntry;
        const configs = this.props.timeTypeConfigurations;

        if (configs.some(t => t.id === timesheetEntry.timeTypeId)) {
            return timesheetEntry.timeTypeId;
        }
        if (timesheetEntry.timeType) {
            const timeType = configs.filter(t => t.typeId === timesheetEntry.timeType.typeId)[0];

            if (timeType) {
                this.props.timesheetEntry.timeTypeId = timeType.id;
                return timeType.id;
            }
        }
        return timesheetEntry.timeTypeId;
    }

    private handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        event.preventDefault();

        this.props.onDelete();
    }

    private handleOnChange(value: number, valid: boolean) {
        this.onChange("timeTypeId", value, valid);
    }

    private getTimeEntryType(value?: number) {
        const timeTypeId = this.props.timesheetEntry && this.props.timesheetEntry.timeTypeId;
        value = value === undefined ? timeTypeId : value;

        const matchingType = this.props.timeTypeConfigurations.filter(t => t.id === value)[0];
        const timeEntryType = matchingType && matchingType.type.timeEntryType;

        if (timeEntryType == null || timeEntryType === 0) {
            return this.props.projectTimeType;
        }

        return timeEntryType;
    }

    private getTimeEntryTypeMultiplier(value?: number) {
        const timeTypeId = this.props.timesheetEntry && this.props.timesheetEntry.timeTypeId;
        value = value === undefined ? timeTypeId : value;

        const matchingType = this.props.timeTypeConfigurations.filter(t => t.id === value)[0];

        if (matchingType == null) {
            return 0;
        }

        return matchingType.multiplier;
    }

    private onChange<T>(prop: keyof TimesheetEntry, value: T, valid: boolean) {

        const newEntry = {
            ...this.props.timesheetEntry,
            [prop]: value
        };

        const newValid = {
            ...this.props.timesheetEntryValid,
            [prop]: valid
        };

        this.props.onChange(newEntry, newValid);
    }

    private getTimeOptionField() {

        if (this.getTimeEntryType() === TimeEntryType.Hours) {
            return this.createNumberInput();
        }

        return this.createDropdownInput();
    }

    private getHoursMinutesField = () => {
        const validations = [];

        if (this.props.isAdjustment) {
            validations.push(negativeTimeValidator);
        }

        if (!this.props.isAdjustment) {
            validations.push(positiveTimeValidator);
        }

        return (
            <Grid.Column mobile={16} computer={2}>
                <DurationInputComponent
                    label="Hours Worked"
                    placeholder="HH:MM"
                    onChange={this.onHoursMinutesChange}
                    value={this.props.timesheetEntry.hoursMinutes}
                    disabled={this.props.submitted}
                    validation={validations}
                    required={this.props.workedHoursRequired}
                    allowNegative={this.props.isAdjustment}
                    showErrors={this.props.showErrors}
                />
            </Grid.Column>
        );
    }

    private onHoursMinutesChange = (value: string, valid: boolean) => {
        this.onChange("hoursMinutes", value, valid);
    }

    private onTimeTypeChange = (value: number, valid: boolean) => {

        const newEntry: TimesheetEntry = {
            ...this.props.timesheetEntry,
            timeTypeId: value
        };

        const newValid: ValidationState = {
            ...this.props.timesheetEntryValid,
            timeTypeId: valid
        };

        if (this.getTimeEntryType(value) !== TimeEntryType.Days) {
            newEntry.hoursMinutes = "";
            newValid.hoursMinutes = true;
        }

        if (this.props.timesheetEntry.timeTypeId !== value) {
            newEntry.amount = 0;
            newValid.amount = false;
        }

        this.props.onChange(newEntry, newValid);
    }

    private onAmountChange = (value: number, valid: boolean) => {

        if (isNullOrUndefined(value) || value === 0) {
            valid = false;
        }

        this.onChange("amount", value, valid);
    }

    private createNumberInput = () => {

        const timeValidation = [];

        if (!this.props.isAdjustment) {
            timeValidation.push(greaterThanZeroValidator());
        }

        return (
            <Grid.Column mobile={16} computer={6}>
                <Input.Number
                    placeholder="Enter time"
                    label="Time"
                    onChange={this.onAmountChange}
                    value={this.props.timesheetEntry.amount}
                    disabled={this.props.submitted}
                    validation={timeValidation}
                    showErrors={this.props.showErrors}
                    required
                />
            </Grid.Column>
        );
    }

    private createDropdownInput() {

        const message = "Please select a time type";
        const timeValidation = [];

        if (this.props.isAdjustment) {
            timeValidation.push(nonZeroValidator(message));
        } else {
            timeValidation.push(greaterThanZeroValidator(message));
        }

        return (
            <Grid.Column mobile={16} computer={6}>
                <Input.DropdownNumber
                    options={this.props.timesheetEntryOptions}
                    placeholder="Enter time"
                    label="Time"
                    onChange={this.onAmountChange}
                    value={this.props.timesheetEntry.amount}
                    disabled={this.props.submitted}
                    showErrors={this.props.showErrors}
                    validation={timeValidation}
                    required
                />
            </Grid.Column>
        );
    }
}
