import React from "react";

import { LocalTextValue } from "../model";

const applicationSubmitted = (
    <>
        <p>
            Thank you for taking the time to apply for a role with Momenta. We will now review your application against the role requirements.
            If your application is shortlisted, you should expect to receive a call from one of our Consultants in the coming days.
        </p>

        <p>
            In the meantime, if you wish to check the status of your application, update your CV/Resume or any personal details,
            or apply for other vacancies you can do this through your Momenta People Candidate Profile.
        </p>

        <p>
            If you would like to be the first to hear about new opportunities, receive regular industry updates and read
            blogs and insights from our team, remember to follow us on
            <a
                href="https://www.linkedin.com/company/momenta-group-global"
                target="_blank"
                rel="noopener noreferrer"
                className="link"> LinkedIn
            </a>.
        </p>

        <p>
            To further assist us we now ask you to complete the fields below. These are not mandatory and will not
            affect your current application however it will help with our recruitment planning.
        </p>

        <p>
            Tell us about your availability and/or notice period and we will try to find a start date that works for you.
        </p>
    </>
);

const applicationSubmittedUsa = (
    <>
        <p>
            Thank you for taking the time to apply for a role with Momenta. We will now review your application against the role
            requirements and determine next steps. If your application is shortlisted, we will be reaching out to you to discuss next steps.
        </p>

        <p>
            In an effort to best assist you with finding your next career move, please ensure your profile is most up to date regarding availability,
            notice period to current employer and any up coming holidays booked.
        </p>

        <p>
            In the meantime, if you wish to check the status of your application, update your CV/Resume or any personal details,
            or apply for other vacancies you can do this through your Momenta People Candidate Profile.
        </p>

        <p>
            If you would like to be the first to hear about new opportunities, receive regular industry updates and read
            blogs and insights from our team, remember to follow us on
            <a
                href="https://www.linkedin.com/company/momenta-group-global"
                target="_blank"
                rel="noopener noreferrer"
                className="link"> LinkedIn
            </a>.
        </p>

        <p>
            To further assist us we now ask you to complete the fields below. These are not mandatory and will not
            affect your current application however it will help with our recruitment planning.
        </p>

        <p>
            Tell us about your availability and/or notice period and we will try to find a start date that works for you.
        </p>
    </>
);

export const applicationSubmittedText: LocalTextValue<React.ReactNode> = {
    default: applicationSubmitted,
    Usa: applicationSubmittedUsa,
};
