import * as React from "react";
import { DropdownItemProps, Grid, Segment } from "semantic-ui-react";

import { TimeEntryType, TimeTypeConfiguration } from "../hierarchicalConfiguration";

import { TimesheetEntryView } from "./TimesheetEntryView";
import { TimesheetDay } from "./model";

interface TimesheetDayViewProps {
    day: TimesheetDay;
    timeTypeConfigurations: TimeTypeConfiguration[];
    projectTimeType: TimeEntryType;
    timesheetEntryOptions: DropdownItemProps[];
}

const TimesheetDayView: React.FC<TimesheetDayViewProps> = ({ day, timeTypeConfigurations, projectTimeType, timesheetEntryOptions }) => {

    return (
        <>
            <Grid width={16}>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <h3>{day.date.format("dddd, MMMM Do YYYY")}</h3>
                    </Grid.Column>
                </Grid.Row>

                {day.timesheetEntries.length === 0 &&
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Segment disabled textAlign="center" basic>
                                No entries for this day
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                }

                {day.timesheetEntries.map((entry, index) => (
                    <TimesheetEntryView
                        key={index}
                        timesheetEntry={entry}
                        timeTypeConfigurations={timeTypeConfigurations}
                        projectTimeType={projectTimeType}
                        timesheetEntryOptions={timesheetEntryOptions}
                    />
                ))}
            </Grid>
        </>
    );
};

export { TimesheetDayView };
