import * as React from "react";
import { DropdownItemProps, Grid } from "semantic-ui-react";
import { stringSort } from "@momenta/common";

import { TimeEntryType, TimeTypeConfiguration } from "../hierarchicalConfiguration";

import { TimesheetEntry } from "./model";

export interface TimesheetEntryViewProps {
    timesheetEntry: TimesheetEntry;
    timeTypeConfigurations: TimeTypeConfiguration[];
    projectTimeType: TimeEntryType;
    timesheetEntryOptions: DropdownItemProps[];
}

class TimesheetEntryView extends React.Component<TimesheetEntryViewProps, {}> {
    constructor(props: TimesheetEntryViewProps) {
        super(props);

        this.state = {
            typeOption: undefined
        };
    }

    public render() {
        const typeOptions = this.props.timeTypeConfigurations
            .sort(stringSort(t => t.type.name))
            .map(t => ({
                text: t.type.name,
                value: t.id
            }));

        const selectedType = typeOptions.filter(t => t.value === this.props.timesheetEntry.timeTypeId)[0];

        const timeType = this.props.timesheetEntry.timeTypeId && selectedType
            ? selectedType.text
            : "No entries for this day";

        return (
            <Grid.Row>
                <Grid.Column mobile={16} computer={6}>
                    <label>{timeType}</label>
                </Grid.Column>
                <Grid.Column mobile={16} computer={6}>
                    {this.getTimeValue(selectedType, this.props.projectTimeType)}
                </Grid.Column>
                <Grid.Column mobile={16} computer={4}>
                    {this.props.timesheetEntry && this.props.timesheetEntry.hoursMinutes}
                </Grid.Column>
            </Grid.Row>
        );
    }

    private getTimeValue(selectedType: { text: string, value: number}, projectTimeType: TimeEntryType) {

        const timeType = selectedType && this.props.timeTypeConfigurations.filter(t => t.id === selectedType.value)[0];
        if (timeType === undefined) {
            return "No time for this day";
        }

        if (timeType.type.timeEntryType === TimeEntryType.Hours
            || (timeType.type.timeEntryType === TimeEntryType.Any && projectTimeType === TimeEntryType.Hours)) {
            return <label>{this.props.timesheetEntry.amount}</label>;
        }

        const optionText = this.props.timesheetEntryOptions.filter(t => t.value === this.props.timesheetEntry.amount);
        const selectedOption = optionText.length ? optionText[0].text : "No time";
        return <label>{selectedOption}</label>;
    }
}

export {
    TimesheetEntryView
};
