import { ExpenseTypeConfiguration } from "../hierarchicalConfiguration";

import { Expense, ExpenseInputTypeEnum } from "./model";

export function getExpenseNetAndVat(expense: Expense, expenseTypeConfigs: ExpenseTypeConfiguration[]) {

    const expenseConfig = expenseTypeConfigs.filter((config: any) => config.id === expense.expenseTypeId)[0];
    const isQuantity = expenseConfig && expenseConfig.type.expenseInputType === ExpenseInputTypeEnum.Quantity;

    const net = isQuantity ? expense.amount * expenseConfig.rate || 0 : expense.net;
    const vat = isQuantity ? 0 : expense.vat || 0;

    return [net, vat];
}
