import { ClientConfig, RegionConfig } from "./model";

// source: https://gist.github.com/hunan-rostomyan/28e8702c1cecff41f7fe64345b76f2ca
export function getCookie<T>(name: string): T {
    const nameLenPlus = (name.length + 1);

    const result = document.cookie
        .split(";")
        .map(c => c.trim())
        .filter(cookie => cookie.substring(0, nameLenPlus) === `${name}=`)
        .map(cookie => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || null;

    return (result && result.charAt(0) === "{")
        ? JSON.parse(result)
        : result;
}

// typescript implementation of: https://github.com/HenrikJoreteg/clientconfig

let config: any;

export const getConfig = <T extends RegionConfig = RegionConfig>(): ClientConfig<T> => {

    if (config !== undefined) {
        return config;
    }

    config = getCookie<ClientConfig<T>>("config") || {};

    // freeze it if browser supported
    if (Object.freeze) {
        Object.freeze(config);
    }

    // return it
    return config;
};
