import moment from "moment";

import { ExpenseType } from "../../People/src/expenseType";
import { TimeType } from "../timeType/model";
import { BonusType } from "../../People/src/bonusType";

export interface ConfigurationType {
    id: number;
    name?: string;
}

export enum Specificity {
    Unknown = 0,
    Client = 10,
    Project = 20,
    Role = 30,
    Intake = 40,
    Candidate = 50
}

export interface HierarchicalConfiguration {
    readonly id: number;
    readonly typeId?: number;
    readonly start?: moment.Moment;
    readonly end?: moment.Moment;
    readonly clientId?: number;
    readonly projectId?: number;
    readonly roleId?: number;
    readonly intakeId?: number;
    readonly candidateId?: number;
    readonly specificity?: Specificity;
    readonly deleted?: boolean;
    readonly deletesId?: number;
}

export interface TypeConfiguration<T> extends HierarchicalConfiguration {
    readonly type?: T;
}

export enum TimeEntryType {
    Any = 0,
    Hours = 10,
    Days = 20
}

export interface TimeTypeConfiguration extends TypeConfiguration<TimeType> {
    readonly multiplier?: number;
}

export interface ExpenseTypeConfiguration extends TypeConfiguration<ExpenseType> {
    dailyLimit?: number;
    receiptRequired: boolean;
    chargeable: boolean;
    rate?: number;
}

export interface BonusTypeConfiguration extends TypeConfiguration<BonusType> {
    bonusLimit: number;
    chargeable: boolean;
}

export interface ValueConfiguration<T = string> extends HierarchicalConfiguration {
    key: string;
    value: T;
}

export enum ValueConstants {
    ChargeRate = "ChargeRate",
    DayRate = "DayRate"
}
export interface VettingTypeConfiguration extends HierarchicalConfiguration {
}

export interface ConfigurationState {
    timeTypeConfiguration?: TimeTypeConfiguration[];
    expenseTypeConfiguration?: ExpenseTypeConfiguration[];
    bonusTypeConfiguration?: BonusTypeConfiguration[];
    vettingTypeConfiguration?: VettingTypeConfiguration[];
    valueConfiguration?: ValueConfiguration[];
}

export type ConfigTypeName = "timeTypeConfiguration"
    | "expenseTypeConfiguration"
    | "bonusTypeConfiguration"
    | "vettingTypeConfiguration"
    | "momentaAssessmentConfiguration"
    | "valueConfiguration";

export type ValueConfigurationKey = "HoursPerDay"
    | "DayRate"
    | "ChargeRate"
    | "NonEmploymentPeriod"
    | "ReferencingPeriod"
    | "TimesheetsRequired";
