import * as React from "react";
import { toast } from "react-toastify";
import { Icon } from "semantic-ui-react";

export const error = (message: string, autoClose: number | false = 5000) =>
    toast(<span><Icon name="ban" /> {message}</span>, { className: "toast-error", autoClose });

export const info = (message: string, autoClose: number | false = 5000) =>
    toast(<span><Icon name="info" /> {message}</span>, { className: "toast-info", autoClose });

export const warning = (message: string, autoClose: number | false = 5000) =>
    toast(<span><Icon name="warning sign" /> {message}</span>, { className: "toast-warning", autoClose });

export const success = (message: string, autoClose: number | false = 5000) =>
    toast(<span><Icon name="checkmark" /> {message}</span>, { className: "toast-success", autoClose });
