import { DropdownItemProps } from "semantic-ui-react";

import { convertEnumToOptions } from "@momenta/common/convertEnumToOptions";

import { LocalTextValue } from "../model";

import { InternalSourceEnum } from "../../../People/src/candidates/model";

const options = convertEnumToOptions(InternalSourceEnum, k => +k).filter(o => o.value > 0);

export const internalSourceDropDownOptions: LocalTextValue<DropdownItemProps[]> = {
    "default": options,
    "India": options.filter(o => o.value !== InternalSourceEnum["Seek Talent (CV database)"]),
    "United Kingdom": options.filter(o => o.value !== InternalSourceEnum["Seek Talent (CV database)"]),
    "Australia": options.filter(o => o.value >= 100
        || o.value === InternalSourceEnum["LinkedId (CV database)"]
        || o.value === InternalSourceEnum["MP2 Search"]),
};
