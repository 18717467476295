import { Payload } from "@neworbit/redux-helpers";
import { loadAndTrack } from "redux-request-loading";
import { AuthState } from "reauthorize";

import * as actions from "./actiontypes";
import { GlobalConfig } from "./model";
import { GlobalConfigApi } from "./globalConfigApi";

const globalConfigApi = new GlobalConfigApi();

export type GlobalConfigAction =
    ({ type: actions.LOAD_GLOBALCONFIG_SUCCESS } & Payload<GlobalConfig[]>)
    | ({ type: actions.SAVE_GLOBALCONFIG_SUCCESS } & Payload<GlobalConfig>)
    | ({ type: actions.DELETE_GLOBALCONFIG_SUCCESS } & Payload<number>);

export const loadGlobalConfigsSuccess = (payload: GlobalConfig[]): GlobalConfigAction => ({
    payload,
    type: actions.LOAD_GLOBALCONFIG_SUCCESS
});

export const saveGlobalConfigsSuccess = (payload: GlobalConfig): GlobalConfigAction => ({
    payload,
    type: actions.SAVE_GLOBALCONFIG_SUCCESS
});

export const deleteGlobalConfigsSuccess = (id: number): GlobalConfigAction => ({
    payload: id,
    type: actions.DELETE_GLOBALCONFIG_SUCCESS
});

export function loadGlobalConfig() {
    return async (dispatch: any, getState: () => AuthState) => {
        if (!getState().currentUser.authenticated) {
            return;
        }

        const result = await loadAndTrack(dispatch, "loadGlobalConfig", globalConfigApi.getAll());
        dispatch(loadGlobalConfigsSuccess(result.items));
    };
}

export function saveGlobalConfig(globalConfig: GlobalConfig) {
    return async (dispatch: any) => {
        const result = await loadAndTrack(dispatch, "saveGlobalConfig", globalConfigApi.save(globalConfig));
        dispatch(saveGlobalConfigsSuccess(result));
    };
}
