import { showBankDetails } from "./showBankDetails";
import { bankCodeName } from "./bankCodeName";
import { accountNumberValidator } from "./accountNumberValidator";
import { bankCodeValidator } from "./bankCodeValidator";
import { showBankAccountName } from "./showBankAccountName";
import { showBankName } from "./showBankName";
import { showBranchName } from "./showBranchName";

export const values = {
    showBankDetails,
    bankCodeName,
    accountNumberValidator,
    bankCodeValidator,
    showBankAccountName,
    showBankName,
    showBranchName
};
