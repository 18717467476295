import { GlobalConfig, GlobalConfigType } from "./model";
import { globalConfigs as reducer } from "./reducer";
import { loadGlobalConfig } from "./actions";

export {
    reducer,
    GlobalConfig,
    loadGlobalConfig,
    GlobalConfigType
};
