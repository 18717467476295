import { Payload } from "@neworbit/redux-helpers";
import { Country } from "@momenta/common/model";

import { SAVE_REGION_SUCCESS } from "./actiontypes";

export type RegionAction = ({ type: SAVE_REGION_SUCCESS } & Payload<Country>);

export const saveRegionSuccess = (payload: Country): RegionAction => ({
    payload,
    type: SAVE_REGION_SUCCESS
});

export function saveRegion(region: Country) {
    return async (dispatch: any) => {
        dispatch(saveRegionSuccess(region));
    };
}
